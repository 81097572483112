import React, {useState} from "react"
import {
  Checkbox,
  Col,
  Card,
  Divider,
  Form,
  Row,
  Typography,
  Input,
  InputNumber,
  Radio,
  Button,
} from "antd"
import {ActionBtnsContainer} from "./UI"
import {ActionBtn} from "./ActionBtn"

export default function ReceptionDecor({
  currentSection,
  prevSection,
  nextSection,
}) {
  const [
    showReceptionTableFlowersOptions,
    setShowReceptionTableFlowersOptions,
  ] = useState(false)
  const [showStaircaseDescription, setShowStaircaseDescription] =
    useState(false)
  const [showCakeTableDescription, setShowCakeTableDescription] =
    useState(false)

  return (
    <div style={{ flex: "none", width: "100%", /* padding: "0px 4px" */ }}>
      <Typography.Title level={4} style={{marginTop: 0, marginBottom: 16}}>
        Reception Flowers
      </Typography.Title>

      <Card bordered={false} style={{marginBottom: "16px"}}>
        <Form.Item name="receptionTableFlowers" valuePropName="checked">
          <Checkbox
            onChange={() =>
              setShowReceptionTableFlowersOptions(
                !showReceptionTableFlowersOptions
              )
            }
          >
            Reception Table Flowers
          </Checkbox>
        </Form.Item>

        {showReceptionTableFlowersOptions && (
          <Form.List name="tableFlowers">
            {(fields, {add, remove}) => (
              <>
                {fields.map((field) => (
                  <Row gutter={24} key={field.key}>
                    <Col span={12}>
                      <Form.Item
                        {...field}
                        name={[field.name, "type"]}
                        fieldKey={[field.fieldKey, "type"]}
                        label="Type of table"
                      >
                        <Radio.Group
                          style={{
                            display: "flex",
                          }}
                        >
                          <Radio.Button value="sweetheart">
                            Sweetheart
                          </Radio.Button>
                          <Radio.Button value="rounds">Rounds</Radio.Button>
                          <Radio.Button value="kings">Kings</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col span={8} style={{paddingLeft: 20}}>
                      <Form.Item
                        {...field}
                        name={[field.name, "count"]}
                        fieldKey={[field.fieldKey, "count"]}
                        label="Count"
                      >
                        <InputNumber
                          min={1}
                          max={50}
                          disabled={fields[field.name]?.type === "sweetheart"}
                          defaultValue={
                            fields[field.name]?.type === "sweetheart"
                              ? 1
                              : undefined
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Button
                        type="dashed"
                        onClick={() => remove(field.name)}
                        style={{margin: "24px 0", float: "right"}}
                        disabled={fields[field.name]?.type === "sweetheart"}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block>
                    Add Another Table Type
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        )}
        <Divider />
        <Form.Item
          name="additionalTablesDescription"
          label="Additional table floral needs (e.g., cocktail tables, escort card display, buffet pieces)"
        >
          <Input.TextArea
            rows={3}
            placeholder="Describe the tables and their floral requirements"
          />
        </Form.Item>

        <Form.Item name="floralsMantle" valuePropName="checked">
          <Checkbox>Florals for the Mantle</Checkbox>
        </Form.Item>
        <Divider />
        <Form.Item name="floralsStaircase" valuePropName="checked">
          <Checkbox
            onChange={() =>
              setShowStaircaseDescription(!showStaircaseDescription)
            }
          >
            Florals for the Staircase
          </Checkbox>
        </Form.Item>
        {showStaircaseDescription && (
          <Form.Item name="staircaseDescription">
            <Input.TextArea
              rows={2}
              placeholder="Describe the florals for the staircase"
            />
          </Form.Item>
        )}
        <Divider />
        <Form.Item name="floralsCakeTable" valuePropName="checked">
          <Checkbox
            onChange={() =>
              setShowCakeTableDescription(!showCakeTableDescription)
            }
          >
            Florals for the Cake/Dessert Table or Loose Flowers for the Cake
          </Checkbox>
        </Form.Item>
        {showCakeTableDescription && (
          <Form.Item name="cakeTableDescription">
            <Input.TextArea
              rows={2}
              placeholder="Describe the florals for the Cake/Dessert Table"
            />
          </Form.Item>
        )}
      </Card>

      <Divider />

      <ActionBtnsContainer>
        {currentSection > 0 && (
          <ActionBtn onClick={prevSection} text="Previous" />
        )}

        <ActionBtn type="primary" text="Continue (step 3 of 4)" htmlType="submit" />
      </ActionBtnsContainer>
    </div>
  )
}
