import React from "react";
import { Button, Col, DatePicker, Form, Input, Row, Typography } from "antd";
import dayjs from "dayjs";
import emailjs from "emailjs-com";
import { useCallback, useState } from "react";
import ReactDOMServer from "react-dom/server";
import InputMask from "react-input-mask";
import styled from "styled-components";
// Icons
import { RiMailSendLine } from "react-icons/ri";
// import { IoIosCalendar } from "react-icons/io";
import { FaRegUser } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
// Modal
import ResponseModal from "../Modal/SuccessModal";

const { TextArea } = Input;

const StyledLinkBtn = styled(Button)`
  margin: 0px;
  padding: 0px;
`;

export function RequestPricingForm({
  title,
  description,
  initialMessage,
  submitBtnText,
  onResponseModalClose,
  form,
  fullWidthFields,
}) {
  // const [form] = Form.useForm();
  const [sendMailLoading, setSendMailLoading] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [hasError, setHasError] = useState(false);

  const disabledDate = useCallback((current) => {
    const today = dayjs();
    const yesterday = today.subtract(1, "day");
    return yesterday.isAfter(current);
  }, []);

  const onFinish = useCallback(async (values) => {
    const allValues = { ...values, eventDate: values.eventDate?.toISOString() };

    // Convert the styledQuote React component to an HTML string
    const quoteHTML = ReactDOMServer.renderToString(allValues.message);

    // Define the parameters for the email
    const emailParams = {
      to_email: "flowerboomdallas@gmail.com",
      subject: "New Floral Quote Request",
      customer_name: allValues?.name,
      customer_email: allValues?.email,
      customer_phone: allValues?.phone,
      eventDate: allValues?.eventDate,
      wedding_date: allValues?.eventDate,
      wedding_vanue: allValues?.receptionLocation,
      ceremony_vanue: allValues?.ceremonyLocation,
      rawHTML: quoteHTML, // This will replace {{rawHTML}} in your EmailJS template
    };

    setSendMailLoading(true);
    // Send the email using EmailJS
    try {
      await emailjs.send(
        "service_imul84s",
        "template_ebv3q6f",
        emailParams,
        "myxzgv1xQl7eTgc8A"
      );
      setResponseModal(true);
      setHasError(false);
      setSendMailLoading(false);
    } catch (error) {
      console.error("Email sending failed:", error);
      setResponseModal(true);
      setHasError(true);
      setSendMailLoading(false);
    }
  }, []);

  const handleSetResponseModal = useCallback(() => {
    setResponseModal(false);
    if (onResponseModalClose) onResponseModalClose();
  }, [onResponseModalClose]);

  return (
    <>
      {!!title && <Typography.Title level={4}>{title}</Typography.Title>}
      {!!description && (
        <Typography.Paragraph style={{ fontSize: "14px" }}>
          {description}
        </Typography.Paragraph>
      )}
      <Form
        title='Lead Form'
        onFinish={onFinish}
        form={form}
        layout='vertical'
        initialValues={{ message: initialMessage }}
        disabled={sendMailLoading}
        style={{ width: "100%" }}
      >
        <Form.Item
          name='message'
          label='Message'
          rules={[
            {
              required: true,
              message: "Please input a message.",
            },
          ]}
        >
          <TextArea rows={4} placeholder={initialMessage} />
        </Form.Item>

        <Form.Item
          name='name'
          label='First and last name'
          rules={[
            {
              required: true,
              message: "Your name must have a minimum of 2 characters.",
            },
          ]}
        >
          <Input
            placeholder='Emily Smith'
            size='large'
            suffix={<FaRegUser />}
          />
        </Form.Item>

        <Row gutter={fullWidthFields ? [0, 0] : [16, 16]}>
          <Col xs={24} md={fullWidthFields ? 24 : 12}>
            <Form.Item
              name='email'
              label='Email'
              rules={[
                {
                  required: true,
                  message: "Please input a valid email address.",
                },
                {
                  type: "email",
                  message: "Check that the email is correct.",
                },
              ]}
            >
              <Input
                suffix={<RiMailSendLine />}
                type='email'
                placeholder='emily.smith@example.com'
                size='large'
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={fullWidthFields ? 24 : 12}>
            <Form.Item
              name='phone'
              label='Phone Number'
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input a valid phone number",
              //   },
              //   {
              //     pattern: /^\(\d{3}\)-\d{3}-\d{4}$/,
              //     message:
              //       "Please enter a valid phone number in the format (123)-456-7890",
              //   },
              // ]}
              // validateTrigger='onBlur'
            >
              <InputMask mask='(999)-999-9999' maskChar={null}>
                {(inputProps) => (
                  <Input
                    {...inputProps}
                    suffix={<FiPhoneCall />}
                    type='tel'
                    placeholder='(214)-111-1234'
                    size='large'
                  />
                )}
              </InputMask>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={fullWidthFields ? [0, 0] : [16, 16]}>
          <Col xs={24} md={fullWidthFields ? 24 : 12}>
            <Form.Item
              name='eventDate'
              label='Event date'
              rules={[
                {
                  required: true,
                  message: "Event date",
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                size='large'
                disabledDate={disabledDate}
                // suffixIcon={
                //   <IoIosCalendar
                //   // color={hasError ? "#ff4d4f" : "rgba(15, 23, 42, 0.88)"}
                //   />
                // }
              />
            </Form.Item>
          </Col>
        </Row>
        <Typography.Paragraph>
          By clicking 'Send', I agree to Flower Boom Dallas'{" "}
          <StyledLinkBtn type='link'>Privacy Policy</StyledLinkBtn> and{" "}
          <StyledLinkBtn type='link'>Terms of Use</StyledLinkBtn>
        </Typography.Paragraph>

        <Button
          style={{ width: "100%" }}
          type='primary'
          size='large'
          htmlType='submit'
          loading={sendMailLoading}
          disabled={sendMailLoading}
        >
          {submitBtnText}
        </Button>
      </Form>
      <ResponseModal
        responseModal={responseModal}
        hasError={hasError}
        setResponseModal={handleSetResponseModal}
        message='We have received your request.'
      />
    </>
  );
}
