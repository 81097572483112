import {Progress} from "antd"
import React, {useEffect, useRef} from "react"

import {GuestsAndBudgetDetails} from "./GuestsAndBudgetDetails"
import {BridalBouquetDetails} from "./BridalBouquetDetails"
import {MohBridesmaidsAndJrBridesmaidsBouquetDetails} from "./MohBridesmaidsAndJrBridesmaidsBouquetDetails"
import {GroomGroomsmen} from "./GroomGroomsmen"
import {CeremonyDecor} from "./CeremonyDecor"
import ReceptionDecor from "./ReceptionDecor"
import {FlowerGirl} from "./FlowerGirl"

export function EventSpecifications({
  form,
  progress,
  sectionRef,
  currentSection,
  prevSection,
  nextSection,
  nextStep,
  prevStep,
  setProgress,
}) {
  const TOTAL_STEPS = useRef(6)
  const PROGRESS_PER_STEP = useRef(100 / TOTAL_STEPS.current)

  useEffect(() => {
    if (sectionRef.current === null) return

    sectionRef.current.scrollLeft =
      currentSection * sectionRef.current.offsetWidth
    // setProgress(() => Math.min(currentSection * 20, 100));
    setProgress(() => Math.min(currentSection * PROGRESS_PER_STEP.current, 100))
  }, [sectionRef, currentSection, setProgress])

  return (
    <>
      <Progress
        percent={progress}
        // strokeColor={{ "0%": "#FFDAB9", "100%": "#FFC085" }}
        strokeColor={{"0%": "#6c23a0", "100%": "#6c23a0"}}
      />
      <div
        ref={sectionRef}
        style={{
          overflowX: "hidden",
          display: "flex",
          scrollBehavior: "smooth",
        }}
      >
        <GuestsAndBudgetDetails prevStep={prevStep} nextSection={nextSection} />

        <BridalBouquetDetails
          currentSection={currentSection}
          form={form}
          prevSection={prevSection}
          nextSection={nextSection}
        />

        <MohBridesmaidsAndJrBridesmaidsBouquetDetails
          currentSection={currentSection}
          form={form}
          prevSection={prevSection}
          nextSection={nextSection}
        />

        {/* Add a new step here */}
        <FlowerGirl
          form={form}
          prevSection={prevSection}
          nextSection={nextSection}
        />

        <GroomGroomsmen
          form={form}
          prevSection={prevSection}
          nextSection={nextSection}
        />

        <CeremonyDecor
          currentSection={currentSection}
          prevSection={prevSection}
          nextSection={nextSection}
        />

        <ReceptionDecor
          currentSection={currentSection}
          prevSection={prevSection}
          nextSection={nextSection}
        />
        
      </div>
    </>
  )
}
