import React from "react"
import {ActionBtnsContainer} from "./UI"
import {ActionBtn} from "./ActionBtn"
import {
  Card,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Typography,
  Divider,
} from "antd"

const {Option} = Select

export function FlowerGirl({form, prevSection, nextSection}) {
  const flowerGirl = Form.useWatch(["flowerGirl"], form)
  const wristCorsageForMother = Form.useWatch(["wristCorsageForMother"], form)
  const wristCorsageForGrandmother = Form.useWatch(
    ["wristCorsageForGrandmother"],
    form
  )

  return (
    <div style={{ flex: "none", width: "100%", /* padding: "0px 4px" */ }}>

      <Card bordered={false} style={{marginBottom: "15px"}}>
        <Row>
          <Col xs={24}>
            <Typography.Title level={5} style={{marginBottom: 16}}>
              Flower Girl(s)
            </Typography.Title>
            <Form.Item
              label="Do you have a flower girl(s)?"
              name="flowerGirl"
              initialValue="no"
            >
              <Radio.Group>
                <Radio value="yes"> Yes </Radio>
                <Radio value="no"> No </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="flowerGirlAge" label="Flower Girl(s) age(s)">
              <Input.TextArea
                rows={2}
                placeholder="e.g. 5, 7"
                disabled={flowerGirl === "no"}
              />
            </Form.Item>
            <Form.Item
              name="flowerGirlFloralsPreferences"
              label="Flower Girl Floral Preferences"
            >
              <Input.TextArea
                rows={2}
                placeholder="Enter your preferences"
                disabled={flowerGirl === "no"}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Divider />

      <Card bordered={false} style={{marginBottom: "15px"}}>
        <Row>
          <Col xs={24}>
            <Typography.Title level={5} style={{marginBottom: 16}}>
              Wrist Corsage(s) For Mother(s)
            </Typography.Title>
            <Form.Item
              label="Do you need wrist corsage(s) for mother(s)?"
              name="wristCorsageForMother"
              initialValue="yes"
            >
              <Radio.Group>
                <Radio value="yes"> Yes </Radio>
                <Radio value="no"> No </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="numberOfWristCorsageForMother"
              label="Number of Wrist Corsages for Mother(s)"
              initialValue="2"
            >
              <Select size="large" disabled={wristCorsageForMother === "no"}>
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Divider />

      <Card bordered={false}>
        <Row>
          <Col xs={24}>
            <Typography.Title level={5} style={{marginBottom: 16}}>
              Wrist Corsage(s) For Grandmother(s)
            </Typography.Title>
            <Form.Item
              label="Do you need wrist corsage(s) for grandmother(s)?"
              name="wristCorsageForGrandmother"
              initialValue="yes"
            >
              <Radio.Group>
                <Radio value="yes"> Yes </Radio>
                <Radio value="no"> No </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="numberOfWristCorsageForGrandmother"
              label="Number of Wrist Corsages for Grandmother(s)"
              initialValue="1"
            >
              <Select
                size="large"
                disabled={wristCorsageForGrandmother === "no"}
              >
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <ActionBtnsContainer>
        <ActionBtn onClick={prevSection} text="Previous" />
        <ActionBtn onClick={nextSection} type="primary" text="Next" />
      </ActionBtnsContainer>
    </div>
  )
}
