import React, { useCallback, useEffect } from "react";
import { Form, Modal } from "antd";

// Components
import { RequestPricingForm } from "components/RequestPricingForm/RequestPricingForm";

export function RequestPricingFormModal({ open, setOpen, textObject }) {
  const [form] = Form.useForm();

  const { title, description, initialMessage, submitBtnText } =
    textObject ?? {};

  useEffect(() => {
    if (!open) return;
    form.setFieldValue("message", initialMessage);
  }, [open, initialMessage, form]);

  const onClose = useCallback(() => {
    form.resetFields();
    setOpen(null);
  }, [setOpen, form]);

  return (
    <Modal
      title='FLOWER BOOM DALLAS LLC'
      centered
      open={open}
      onOk={onClose}
      onCancel={onClose}
      width={768}
      footer={null} // if footer is null then action buttons does not show
      okButtonProps={{
        size: "large",
        // loading: loading,
        // disabled: currentStep < 3,
      }}
      okText='Send'
      cancelButtonProps={{
        size: "large",
        // disabled: loading,
      }}
      confirmLoading
      cancelText='Close'
    >
      <RequestPricingForm
        description={description}
        initialMessage={initialMessage}
        onResponseModalClose={onClose}
        submitBtnText={submitBtnText}
        title={title}
        form={form}
      />
    </Modal>
  );
}
