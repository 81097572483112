import React, { useState } from "react";
import {
  Checkbox,
  Card,
  Form,
  Typography,
  Input,
  Radio,
  Space,
  Row,
  Col
} from "antd";
import { ActionBtnsContainer } from "./UI";
import { ActionBtn } from "./ActionBtn";

export default function VendorDetails({
  currentSection,
  prevSection,
  nextSection,
}) {
  const [vendorDetails, setVendorDetails] = useState({
    coordinator: false,
    photoVideo: false,
    caterer: false,
    dj: false
  });

  const handleVendorCheckboxChange = (vendor) => {
    setVendorDetails((prev) => ({
      ...prev,
      [vendor]: !prev[vendor]
    }));
  };

  return (
    <div style={{ flex: "none", width: "100%", /* padding: "0px 4px" */ }}>
      <Typography.Title level={4} style={{ marginTop: 0, marginBottom: 24 }}>
        Vendors
      </Typography.Title>

      <Card bordered={false} style={{ marginBottom: "24px" }}>
        <Typography.Paragraph style={{ marginBottom: 24 }}>
          Please list any vendors you have booked so far. We will make the collaboration smooth and hassle-free for you.
        </Typography.Paragraph>

        <Space direction="vertical" size={12}>
          <Form.Item name="coordinator" valuePropName="checked">
            <Checkbox onChange={() => handleVendorCheckboxChange("coordinator")}>
              Coordinator
            </Checkbox>
          </Form.Item>
          {vendorDetails.coordinator && (
            <Input placeholder="Enter coordinator's Name" style={{ marginBottom: 16 }}/>
          )}

          <Form.Item name="photoVideo" valuePropName="checked">
            <Checkbox onChange={() => handleVendorCheckboxChange("photoVideo")}>
              Photographer/Videographer
            </Checkbox>
          </Form.Item>
          {vendorDetails.photoVideo && (
            <Input placeholder="Enter photographer/videographer's Name" style={{ marginBottom: 16 }}/>
          )}

          <Form.Item name="caterer" valuePropName="checked">
            <Checkbox onChange={() => handleVendorCheckboxChange("caterer")}>
              Caterer
            </Checkbox>
          </Form.Item>
          {vendorDetails.caterer && (
            <Input placeholder="Enter caterer's Name" style={{ marginBottom: 16 }}/>
          )}

          <Form.Item name="dj" valuePropName="checked">
            <Checkbox onChange={() => handleVendorCheckboxChange("dj")}>
              DJ
            </Checkbox>
          </Form.Item>
          {vendorDetails.dj && (
            <Input placeholder="Enter DJ's Name" style={{ marginBottom: 16 }}/>
          )}

          <Form.Item style={{ marginTop: 24 }} label="How quickly are you looking to book a florist?">
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="immediately">Immediately</Radio>
                <Radio value="withinNextMonth">Within the next month</Radio>
                <Radio value="withinNext3Months">Within next 3 months</Radio>
                <Radio value="priceShopping">Price shopping for now</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item style={{ marginTop: 24 }} label="Where did you hear about us?">
            <Radio.Group>
              <Space direction="vertical" size={8}>
                <Row gutter={16}>
                  <Col span={8}><Radio value="google">Google</Radio></Col>
                  <Col span={8}><Radio value="theKnot">The Knot</Radio></Col>
                  <Col span={8}><Radio value="weddingWire">WeddingWire</Radio></Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}><Radio value="instagram">Instagram</Radio></Col>
                  <Col span={8}><Radio value="facebook">Facebook</Radio></Col>
                  <Col span={8}><Radio value="pinterest">Pinterest</Radio></Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}><Radio value="friendReferral">Friend Referral</Radio></Col>
                  <Col span={8}><Radio value="venueReferral">Venue Referral</Radio></Col>
                  <Col span={8}><Radio value="vendorReferral">Vendor Referral</Radio></Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}><Radio value="flowerBoomDallasWebsite">Flower Boom Dallas' Website</Radio></Col>
                  <Col span={8}><Radio value="other">Other</Radio></Col>
                </Row>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Space>
      </Card>

      <ActionBtnsContainer style={{ marginTop: "16px" }}>
        {currentSection > 0 && (
          <ActionBtn onClick={prevSection} text="Previous" />
        )}
        <ActionBtn type='primary' text='Done (step 4 of 4)' htmlType='submit' />
      </ActionBtnsContainer>
    </div>
  );
}
