import {
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Typography,
  Tooltip,
  Divider,
  Card
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import React from "react";
import { ActionBtnsContainer } from "./UI";
import { ActionBtn } from "./ActionBtn";

export function MohBridesmaidsAndJrBridesmaidsBouquetDetails({
  currentSection,
  form,
  prevSection,
  nextSection,
}) {
  const mohBouquet = Form.useWatch(["mohBouquet"], form);
  const bridesmaidsBouquet = Form.useWatch(["bridesmaidsBouquet"], form);
  const jrBridesmaidsBouquet = Form.useWatch(["jrBridesmaidsBouquet"], form);

  return (
    <div style={{ flex: "none", width: "100%", /* padding: "0px 4px" */ }}>
      <Typography.Title level={4} style={{ marginTop: 0, padding: "15px 0 15px 0" }}>
        MOH, Bridesmaids & Jr. Bridesmaids bouquet details
      </Typography.Title>
      
      <img
        src="/images/moh-bridesmaids-details-2.png"
        alt="MOH, Bridesmaids & Jr. Bridesmaids bouquet details"
        width="100%"
        height="auto"
        hidden={currentSection !== 2}
      />

      <Divider />

      <Card bordered={false} style={{ marginBottom: "15px" }}>
        <Row>
          <Col xs={24}>
            <Form.Item name="mohBouquet" valuePropName="checked" initialValue={true}>
              <Checkbox>
                MOH Bouquet <Tooltip title="MOH stands for 'Maid of Honor'. In a wedding, the Maid of Honor is typically the bride's closest female friend or relative."><QuestionCircleOutlined /></Tooltip>
              </Checkbox>
            </Form.Item>
            <Form.Item label={`Number of MOH Bouquet`} name="noOfMohBouquet" initialValue={1}>
              <InputNumber size="large" min={1} max={20} style={{ width: "100%" }} disabled={!mohBouquet} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="mohBouquetPreferences" label="MOH Bouquet Preferences">
              <Input.TextArea rows={2} disabled={!mohBouquet} />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Divider />

      <Card bordered={false} style={{ marginBottom: "15px" }}>
        <Row>
          <Col xs={24}>
            <Form.Item name="bridesmaidsBouquet" valuePropName="checked" initialValue={true}>
              <Checkbox>Bridesmaids Bouquet</Checkbox>
            </Form.Item>
            <Form.Item label={`Number of Bridesmaids Bouquet`} name="noOfBridesmaidsBouquet" initialValue={1}>
              <InputNumber size="large" min={1} max={20} style={{ width: "100%" }} disabled={!bridesmaidsBouquet} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="bridesmaidsBouquetPreferences" label="Bridesmaids Bouquet Preferences">
              <Input.TextArea rows={2} disabled={!bridesmaidsBouquet} />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Divider />

      <Card bordered={false}>
        <Row>
          <Col xs={24}>
            <Form.Item name="jrBridesmaidsBouquet" valuePropName="checked" initialValue={true}>
              <Checkbox>Jr. Bridesmaids Bouquet</Checkbox>
            </Form.Item>
            <Form.Item label={`Number of Jr. Bridesmaids Bouquet`} name="noOfJrBridesmaidsBouquet" initialValue={1}>
              <InputNumber size="large" min={1} max={20} style={{ width: "100%" }} disabled={!jrBridesmaidsBouquet} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="jrBridesmaidsBouquetPreferences" label="Jr. Bridesmaids Bouquet Preferences">
              <Input.TextArea rows={2} disabled={!jrBridesmaidsBouquet} />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <ActionBtnsContainer>
        {currentSection > 0 && <ActionBtn onClick={prevSection} text="Previous" />}
        <ActionBtn onClick={nextSection} type="primary" text="Next" />
      </ActionBtnsContainer>
    </div>
  );
}
