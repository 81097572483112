import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import QAPI from "qapi";
import './index.scss'
window.QAPI = QAPI;
// This script is required for instant-wedding-quote page to work
// that's why I have moved this script to App.js file
// Dynamically create a script tag for the Google Maps JavaScript API
// const script = document.createElement('script');
// script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
// document.body.appendChild(script);

ReactDOM.render(<App />, document.getElementById("root"));
