import { Form, Input, Col, Row, DatePicker, TimePicker } from "antd";
import InputMask from "react-input-mask";
import PlacesAutocomplete from "react-places-autocomplete";
import React, { useCallback } from "react";
import { ActionBtn } from "./ActionBtn";
import styled from "styled-components";
import dayjs from "dayjs";

const SuggestionsContainer = styled.div`
  position: absolute;
  width: 100%;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

export function EventDetails({
  ceremonyLocation,
  handleCeremonyLocationChange,
  getDisabledHours,
  getDisabledMinutes,
  receptionLocation,
  handleReceptionLocationChange,
}) {
  const disabledDate = useCallback((current) => {
    const today = dayjs();
    const yesterday = today.subtract(1, "day");
    return yesterday.isAfter(current);
  }, []);

  return (
    <Row gutter={24} style={{ padding: "0px 4px" }}>
      {/* Full Name */}
      <Col xs={24} md={12}>
        <Form.Item
          name='fullName'
          label='Full Name'
          rules={[{ required: true, message: "Please input your full name" }]}
        >
          <Input placeholder='Emily Smith' size='large' />
        </Form.Item>
      </Col>

      {/* Email Address */}
      <Col xs={24} md={12}>
        <Form.Item
          name='email'
          label='Email Address'
          rules={[
            { required: true, message: "Please input a valid email address" },
            { type: "email", message: "The input is not a valid email" },
          ]}
        >
          <Input type='email' placeholder='emily.smith@example.com' size='large' />
        </Form.Item>
      </Col>

      {/* Phone Number */}
      <Col xs={24} md={12}>
        <Form.Item
          name='phone'
          label='Phone Number'
          rules={[
            { required: true, message: "Please input a valid phone number" },
            {
              pattern: /^\(\d{3}\)-\d{3}-\d{4}$/,
              message:
                "Please enter a valid phone number in the format (123)-456-7890",
            },
          ]}
          validateTrigger='onBlur'
        >
          <InputMask mask='(999)-999-9999' maskChar={null}>
            {(inputProps) => (
              <Input
                {...inputProps}
                type='tel'
                placeholder='(214)-111-1234'
                size='large'
              />
            )}
          </InputMask>
        </Form.Item>
      </Col>

      {/* Wedding Date */}
      <Col xs={24} md={12}>
        <Form.Item
          name='weddingDate'
          label='Wedding Date'
          rules={[
            {
              required: true,
              message: "Wedding Date is required",
            },
          ]}
        >
          <DatePicker
            style={{ width: "100%" }}
            size='large'
            disabledDate={disabledDate}
          />
        </Form.Item>
      </Col>

      {/* Ceremony Location */}
      <Col xs={24} md={12}>
        <Form.Item label='Ceremony Location' name='ceremonyLocation'>
          <PlacesAutocomplete
            value={ceremonyLocation}
            onChange={handleCeremonyLocationChange}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <Input
                  {...getInputProps({
                    placeholder: "Ceremony Location",
                    style: { width: "100%" },
                  })}
                  size='large'
                />
                <SuggestionsContainer>
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion, index) => (
                    <div
                      key={suggestion.id + "-" + index}
                      {...getSuggestionItemProps(suggestion, {
                        style: { padding: "10px", cursor: "pointer" },
                      })}
                    >
                      {suggestion.description}
                    </div>
                  ))}
                </SuggestionsContainer>
              </div>
            )}
          </PlacesAutocomplete>
        </Form.Item>
      </Col>

      {/* Ceremony Time */}
      <Col xs={24} md={12}>
        <Form.Item name='ceremonyTime' label='Ceremony Time'>
          <TimePicker
            style={{ width: "100%" }}
            use12Hours
            format='h:mm a'
            size='large'
            hideDisabledOptions
            disabledTime={() => {
              return {
                disabledHours: getDisabledHours,
                disabledMinutes: getDisabledMinutes,
              };
            }}
          />
        </Form.Item>
      </Col>

      {/* Reception Location */}
      <Col xs={24} md={12}>
        <Form.Item label='Reception Location' name='receptionLocation'>
          <PlacesAutocomplete
            value={receptionLocation}
            onChange={handleReceptionLocationChange}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <Input
                  {...getInputProps({
                    placeholder: "Reception Location",
                    style: { width: "100%" },
                  })}
                  size='large'
                />
                <SuggestionsContainer>
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion, index) => (
                    <div
                      key={suggestion.id + "-" + index}
                      {...getSuggestionItemProps(suggestion, {
                        style: { padding: "10px", cursor: "pointer" },
                      })}
                    >
                      {suggestion.description}
                    </div>
                  ))}
                </SuggestionsContainer>
              </div>
            )}
          </PlacesAutocomplete>
        </Form.Item>
      </Col>

      {/* Reception Time */}
      <Col xs={24} md={12}>
        <Form.Item name='receptionTime' label='Reception Time'>
          <TimePicker
            style={{ width: "100%" }}
            use12Hours
            format='h:mm a'
            size='large'
            hideDisabledOptions
            disabledTime={() => {
              return {
                disabledHours: getDisabledHours,
                disabledMinutes: getDisabledMinutes,
              };
            }}
          />
        </Form.Item>
      </Col>

      {/* Next Button */}
      <Col xs={24} style={{ display: "flex", justifyContent: "end" }}>
        <ActionBtn type='primary' text='Continue (step 1 of 4)' htmlType='submit' />
      </Col>
    </Row>
  );
}
