import {
  Checkbox,
  Card,
  Divider,
  Form,
  Typography,
  Input,
  Select,
} from "antd";
import React, { useState } from "react";
import { ActionBtnsContainer } from "./UI";
import { ActionBtn } from "./ActionBtn";

const { Option } = Select;

export function CeremonyDecor({
  currentSection,
  prevSection,
  nextSection,
}) {
  const [showAisleDescription, setShowAisleDescription] = useState(false);
  const [showPewMarkerCount, setShowPewMarkerCount] = useState(false);
  const [showArchDescription, setShowArchDescription] = useState(false);

  return (
    <div style={{ flex: "none", width: "100%", /* padding: "0px 4px" */ }}>
      <Typography.Title level={4} style={{ marginTop: 0, marginBottom: 16 }}>
        Ceremony Flowers
      </Typography.Title>

      <Card bordered={false} style={{ marginBottom: "16px" }}>
        {/* Aisle */}
        <Form.Item name="floralsAisle" valuePropName="checked">
          <Checkbox
            onChange={() => setShowAisleDescription(!showAisleDescription)}
          >
            Florals for the Aisle
          </Checkbox>
        </Form.Item>
        {showAisleDescription && (
          <Form.Item name="floralsAisleDescription">
            <Input placeholder="Describe the florals for the aisle" />
          </Form.Item>
        )}

        <Divider />

        {/* Pew Markers */}
        <Form.Item name="needPewMarkers" valuePropName="checked">
          <Checkbox
            onChange={() => setShowPewMarkerCount(!showPewMarkerCount)}
          >
            Pew Markers
          </Checkbox>
        </Form.Item>
        {showPewMarkerCount && (
          <Form.Item name="pewMarkersCount" label="Pew Markers Count">
            <Select placeholder="Select the number of Pew Markers">
              {[4, 8, 12, 16, 20, 30].map((count) => (
                <Option key={count} value={count}>
                  {count}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Divider />

        {/* Arch */}
        <Form.Item name="needArch" valuePropName="checked">
          <Checkbox onChange={() => setShowArchDescription(!showArchDescription)}>
            Floral Arch
          </Checkbox>
        </Form.Item>
        {showArchDescription && (
          <Form.Item name="archDescription">
            <Input placeholder="Describe the floral arch" />
          </Form.Item>
        )}
      </Card>

      <Divider />

      <ActionBtnsContainer style={{ marginTop: "16px" }}>
        {currentSection > 0 && (
          <ActionBtn onClick={prevSection} text="Previous" />
        )}
        <ActionBtn onClick={nextSection} type="primary" text="Next" />
      </ActionBtnsContainer>
    </div>
  );
}
