import React, { useEffect, useState } from "react";
import Index from "pages/index";
import Page404 from "pages/page404";
import InstantWeddingQuote from "pages/InstantWeddingQuote";
import StoreFrontGallery from "pages/StoreFrontGallery";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { ConfigProvider } from "antd";

const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
        box-sizing: border-box;
    }
    h1, h2, h3, h4, h5, h6 {
      margin-top: 0px;
    }
`;

export default () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    script.async = true;

    // Function to update state when script has loaded
    const onScriptLoad = () => setScriptLoaded(true);

    // Function to handle script loading error
    const onScriptError = () => {
      console.error('Error loading Google Maps API script.');
      // Handle error as needed (e.g., show an error message to the user)
    };

    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);

    document.body.appendChild(script);

    // Cleanup listener on component unmount
    return () => {
      script.removeEventListener('load', onScriptLoad);
      script.removeEventListener('error', onScriptError);
    };
  }, []);
  

  if (!scriptLoaded) return null; // we can also show loading
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "var(--qtheme-font-headline2)",
          colorPrimary: "#6c23a0", // "#4d157a",
          colorTextBase: "#0f172a",
          fontSize: 16,
        },
        components: {
          Typography: {
            titleMarginTop: "0px", // Not working
            titleMarginBottom: "0px", // Not working
          },
        },
      }}
    >
      <Router>
        <GlobalStyles />
        <Switch>
          <Route exact path='/' component={Index} />
          <Route exact path='/instant-wedding-quote' component={InstantWeddingQuote} />
          <Route exact path='/store-front-gallery/:mediaType' component={StoreFrontGallery} />
          <Route exact path='/index' component={Index} />
          <Route component={Page404} />
        </Switch>
      </Router>
    </ConfigProvider>
  )
};
