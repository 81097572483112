import React from "react";
import theme from "theme";
import { Theme, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
			Instant Wedding Floral Quotes by Flower Boom Dallas
			</title>
			<meta name={"description"} content={"Get an instant floral quote tailored for your wedding with Flower Boom Dallas. Save time, visualize your dream, and turn it into a floral reality."} />
			<meta property={"og:title"} content={"Flower Boom Dallas: Your Dream Wedding, One Floral Design at a Time"} />
			<meta property={"og:description"} content={"Turn your wedding into a floral masterpiece with Flower Boom Dallas. Get an instant estimate and start your journey to a magical wedding today!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/tall-centerpiece-white-pink_51_2055487-169163449046087.webp?v=2023-09-06T05:42:29.723Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"64f7edaf098f0700199b65ed"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});