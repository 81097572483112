import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {Button, Col, Flex, Row, Typography} from "antd"
import {StarFilled} from "@ant-design/icons"
import {
  IoLocationOutline,
  IoPlayCircleOutline,
  IoImageOutline,
} from "react-icons/io5"
import {BsTag} from "react-icons/bs"
import {TbCoins} from "react-icons/tb"
import {PiPhoneCallLight} from "react-icons/pi"
import {RiMailSendLine} from "react-icons/ri"
import {MdSmartDisplay} from "react-icons/md"
import styled from "styled-components"

// *components
import {RequestPricingFormModal} from "../RequestPricingFormModal/RequestPricingFormModal"

// *Styles
import "../../styles/request-pricing.scss"
import {CallVendorModal} from "components/Modal/CallVendorModal/CallVendorModal"

const StyledVideoAndPhotosBtnsContainer = styled(Row)`
  position: absolute;
  right: 0px;
  left: 2px;
  bottom: 12px;
  padding: 0px 8px;
  width: 100%;
`

const BorderedBtn = styled(Button)`
  border: 1px solid rgba(15, 23, 42, 0.88);
`

export function HomeImageGallery({history}) {
  const images = useMemo(
    () => [
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_0188.jpg?v=1710216864",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/Brianne_Ivan-Wedding-541.jpg?v=1710216800",
      "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/img-5217_51_2055487-166881243966053.webp?v=2023-09-06T05:42:29.560Z",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/350772610_1000850054626827_6594566934767196212_n.jpg?v=1710216767",
    ],
    []
  )

  const [openModalAndSetTextObject, setOpenModalAndSetTextObject] =
    useState(null)

  const handleVideosBtnPress = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      history.push("/store-front-gallery/videos")
    },
    [history]
  )
  const handlePhotosBtnPress = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      history.push("/store-front-gallery/photos")
    },
    [history]
  )

  return (
    <Row
      style={{maxWidth: "1400px", margin: "0px auto", padding: "0px 16px"}}
      gutter={[40, 16]}
    >
      <RequestPricingFormModal
        open={!!openModalAndSetTextObject}
        setOpen={setOpenModalAndSetTextObject}
        textObject={openModalAndSetTextObject}
      />
      <Col xs={24} lg={16}>
        <div className="home-gallery-grid-container">
          {images.map((imgUrl, index) => {
            let button = <></>
            if (index === 0) {
              button = (
                <BorderedBtn
                  icon={<IoPlayCircleOutline size="22px" />}
                  size="large"
                  style={{
                    paddingTop: "8px",
                    position: "absolute",
                    left: "12px",
                    bottom: "12px",
                  }}
                  onClick={handleVideosBtnPress}
                />
                
              )
            }
            if (index === 3)
              button = (
                <StyledVideoAndPhotosBtnsContainer gutter={[8, 8]}>
                  <Col xs={24} sm={12} lg={24} xl={12}>
                    <BorderedBtn
                      size="large"
                      style={{width: "100%"}}
                      onClick={handleVideosBtnPress}
                    >
                      <Flex align="center" justify="center" gap={6}>
                        <MdSmartDisplay size="22px" />
                        View Videos 1
                      </Flex>
                    </BorderedBtn>
                  </Col>
                  <Col xs={24} sm={12} lg={24} xl={12}>
                    <BorderedBtn
                      size="large"
                      style={{width: "100%", backgroundColor: "#FFE499"}}
                      onClick={handlePhotosBtnPress}
                    >
                      <Flex align="center" justify="center" gap={6}>
                        <IoImageOutline size="22px" />
                        View Photos 76
                      </Flex>
                    </BorderedBtn>
                  </Col>
                </StyledVideoAndPhotosBtnsContainer>
              )

            return (
              <div
                key={index}
                style={{position: "relative", cursor: "pointer"}}
                className={"gallery-image-div" + index}
                onClick={handlePhotosBtnPress}
              >
                <img src={imgUrl} alt={"gallery-img-" + index} />
                {button}
              </div>
            )
          })}
        </div>
      </Col>
      {/* Form placeholder */}
      <Col xs={24} lg={8} style={{paddingLeft: "16px"}}>
        <Typography.Title level={3} style={{marginBottom: "12px", fontWeight: "normal"}}>
          Flower Boom Dallas LLC
        </Typography.Title>
        <Flex gap={15} vertical>
          <Flex align="center" gap={20}>
            <Flex align="center">
              {Array(5)
                .fill(0)
                .map((_, idx) => (
                  <StarFilled
                    key={idx}
                    size="18px"
                    style={{
                      color: "#fabb00",
                    }}
                  />
                ))}
            </Flex>

            <Typography.Title
              level={4}
              style={{fontSize: "16px", fontWeight: "normal"}}
            >
              4.9 Fantastic
            </Typography.Title>
            <Typography.Text
              style={{textDecorationLine: "underline", fontWeight: "normal"}}
            >
              739 reviews
            </Typography.Text>
          </Flex>

          <Flex gap={35} vertical>
            <Flex align="center" gap={20}>
              <IoLocationOutline />
              <Typography.Text style={{textDecorationLine: "underline"}}>
                Dallas Fort-Worth Metroplex
              </Typography.Text>
            </Flex>

            <RenderDealBtn
              setOpenModalAndSetTextObject={setOpenModalAndSetTextObject}
            />
          </Flex>

          <Flex
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "8px 16px",
              backgroundColor: "white",
              borderRadius: "8px",
              height: "48px",
            }}
            align="center"
            gap={10}
          >
            <TbCoins fontSize="18px"/>
            <Typography.Text>$1,500 Typical Price</Typography.Text>
          </Flex>
          <RenderRequestPricingAndCallBtns
            setOpenModalAndSetTextObject={setOpenModalAndSetTextObject}
          />
        </Flex>
      </Col>
    </Row>
  )
}

function RenderDealBtn({setOpenModalAndSetTextObject}) {
  const getDealFormText = useMemo(() => {
    return {
      title: "Get deal",
      description:
        "Please complete the following form to get this deal voucher. Flower Boom Dallas LLC will contact you separately to provide more information about this deal.",
      initialMessage:
        "I am interested in this deal for 15% discount for Flower Boom Dallas couples and would like to receive more information.",
      submitBtnText: "Get deal",
    }
  }, [])

  return (
    <Button
      size="large"
      onClick={() => setOpenModalAndSetTextObject(getDealFormText)}
    >
      <Flex align="center" gap={10}>
        <BsTag />
        <Typography.Text style={{textDecorationLine: "underline"}}>
          1 deal
        </Typography.Text>

        <Typography.Text
          style={{
            fontWeight: "700",
            color: "#6C23A0",
          }}
        >
          15% discount
        </Typography.Text>
      </Flex>
    </Button>
  )
}

function RenderRequestPricingAndCallBtns({setOpenModalAndSetTextObject}) {
  const btnContainerRef = useRef(null)
  const requestPricingBtnRef = useRef(null)
  const requestPricingCallBtnRef = useRef(null)

  const requestFormText = useMemo(() => {
    return {
      title: "Message vendor",
      description:
        "Fill out the form below to request information from Flower Boom Dallas LLC.",
      initialMessage:
        "We were excited to find you on Flower Boom Dallas! We'd love to get more information about your services and any packages you have available.",
      submitBtnText: "Send",
    }
  }, [])

  const [showCallModal, setShowCallModal] = useState(false)

  useEffect(() => {
    const handleResizeAndScroll = () => {
      const documentWidth = document.documentElement.clientWidth
      const scrollPosition = document.documentElement.scrollTop

      // Adjust this value to change when the sticky panel appears
      const scrollThreshold = window.innerHeight * 0.55 // Example: 70% of the viewport height

      if (documentWidth < 768 && scrollPosition > scrollThreshold) {
        if (
          btnContainerRef.current &&
          requestPricingBtnRef.current &&
          requestPricingCallBtnRef.current
        ) {
          btnContainerRef.current.classList.add(
            "request-pricing-btns-container-sm-to-md"
          )
          requestPricingBtnRef.current.style.height = "56px"

          requestPricingCallBtnRef.current.style.paddingTop = "16px"
          requestPricingCallBtnRef.current.style.height = "56px"
          requestPricingCallBtnRef.current.style.width = "62px"
        }
      } else {
        btnContainerRef.current.classList.remove(
          "request-pricing-btns-container-sm-to-md"
        )
        requestPricingBtnRef.current.style.height = "48px"

        requestPricingCallBtnRef.current.style.paddingTop = "12px"
        requestPricingCallBtnRef.current.style.height = "48px"
        requestPricingCallBtnRef.current.style.width = "48px"
      }
    }

    // Call it for the first time
    handleResizeAndScroll()

    // Add event listeners for resize and scroll
    document.addEventListener("resize", handleResizeAndScroll)
    document.addEventListener("scroll", handleResizeAndScroll)

    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener("resize", handleResizeAndScroll)
      document.removeEventListener("scroll", handleResizeAndScroll)
    }
  }, [])

  return (
    <>
      <CallVendorModal
        open={showCallModal}
        onClose={() => setShowCallModal(false)}
      />
      <Flex align="center" gap={8} ref={btnContainerRef}>
        <Button
          type="primary"
          size="large"
          className="request-pricing-btn"
          onClick={() => setOpenModalAndSetTextObject(requestFormText)}
          ref={requestPricingBtnRef}
        >
          <Flex align="center" justify="center" gap={6}>
            <RiMailSendLine size="22px" />
            Request pricing
          </Flex>
        </Button>
        <Button
          icon={<PiPhoneCallLight size="22px" />}
          size="large"
          className="request-pricing-call-btn"
          ref={requestPricingCallBtnRef}
          onClick={() => setShowCallModal(true)}
        />
      </Flex>
    </>
  )
}
