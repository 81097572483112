import {Col, Form, Row, Select, Typography} from "antd"
import React from "react"
import {ActionBtnsContainer} from "./UI"
import {ActionBtn} from "./ActionBtn"

const {Option} = Select

export function GuestsAndBudgetDetails({prevStep, nextSection}) {
  return (
    <div style={{flex: "none", width: "100%" /* padding: "0px 4px" */}}>
      <Typography.Title
        level={4}
        style={{marginTop: 0, padding: "15px 0 15px 0"}}
      >
        Guests and Budget Details
      </Typography.Title>
      <Row gutter={24}>
        <Col xs={24} md={12}>
          <Form.Item
            name="numberOfGuests"
            label="Number of Guests"
            rules={[{required: true}]}
            initialValue="Under 50"
          >
            <Select size="large">
              <Option value="Under 50">Under 50</Option>
              <Option value="50-150">50 - 150</Option>
              <Option value="150-250">150 - 250</Option>
              <Option value="250+">250+</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="budget"
            label="Budget"
            rules={[{required: true, message: "Please select your budget"}]}
            initialValue="1500-2500" // Set the default value here
          >
            <Select size="large">
              <Option value="1500-2500">1500 - 2500</Option>
              <Option value="2500-5000">2500 - 5000</Option>
              <Option value="5000-10000">5000 - 10000</Option>
              <Option value="10000-15000">10000 - 15000</Option>
              <Option value="15000+">15000+</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <ActionBtnsContainer>
        <ActionBtn onClick={prevStep} text="Previous" />
        <ActionBtn onClick={nextSection} type="primary" text="Next" />
      </ActionBtnsContainer>
    </div>
  )
}
