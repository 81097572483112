import React, { Fragment, useEffect, useState } from 'react';
import Popup from '../components/Popup';
import theme from "theme";
import { Theme, Link, Image, Text, LinkBox, Box, Section, Span, Button, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { FiMenu } from "react-icons/fi";
import { AiOutlineMenu } from "react-icons/ai";
import { CalendarOutlined } from '@ant-design/icons';
import { IoIosCheckmark, IoMdCheckmark, IoIosClose } from "react-icons/io";
import styled from 'styled-components';
import { GradientBtn } from 'components/GradientBtn';
import { HomeImageGallery } from 'components/HomeImageGallery/HomeImageGallery';
import { ChoosePathSection } from 'components/ChoosePathSection/ChoosePathSection';
import StructuredData from '../UI/structuredData';


const allImages = [
	"https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/purple-jasmine-centerpiece_51_2055487-169163449611963.webp?v=2023-09-06T05:42:29.529Z",
	"https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/img-6307_51_2055487-168903822678687.webp?v=2023-09-06T05:42:29.542Z",
	"https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/img-5348_51_2055487-166881243955612.webp?v=2023-09-06T05:42:29.542Z",
	"https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/img-5217_51_2055487-166881243966053.webp?v=2023-09-06T05:42:29.560Z",
	"https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/tall-centerpiece-white-pink_51_2055487-169163449046087.webp?v=2023-09-06T05:42:29.723Z",
	"https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/purple-jasmine-centerpiece_51_2055487-169163449611963.webp?v=2023-09-06T05:42:29.529Z",
	"https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/brianneivan-wedding-633_51_2055487-169155192853041.webp?v=2023-09-06T05:42:29.553Z",
	"https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/green-white-aisle_51_2055487-169163447888235.webp?v=2023-09-06T05:42:29.540Z",
	"https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/img-5341_51_2055487-166881513117265.webp?v=2023-09-06T05:42:29.541Z",
	"https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/balls-centerpiece-tall_51_2055487-169163447096918.webp?v=2023-09-06T05:42:29.533Z",
	"https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/purple-jasmine-centerpiece_51_2055487-169163449611963.webp?v=2023-09-06T05:42:29.529Z"
]

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 30px 0px;
  width: 100%;
  margin-bottom:40px;
  @media (max-width: 768px) {
	border-spacing: 10px 0px;
  }
 tr > td{	
	height:60px;
	@media (max-width: 1215px) {
		height:90px;
	  }
	@media (max-width: 768px) {
		height:70px;
	  }
}
`;

const cellStyle = {
	width: '25%',
	textAlign: 'center',
	verticalAlign: "center"
};

const darkCellStyle = {
	...cellStyle,
	backgroundColor: "#1d1724",
	borderColor: "#26212d",
};

const lightCellStyle = {
	...cellStyle,
	background: "#F7FBFF",
	border: "none"
};

const headingArray = [
	"Wedding Sets",
	"Budget Deals",
	"Top Reviews",
	"Payment Schedule",
	"Venue Partners",
	"Local Flowers",
	"Delivery",
]

const tabelData = [{
	length: 8,
	iconsTypeOne: <Box
		height="30px"
		sm-height="40px"
		md-height="50px"
		lg-height="60px"
		display="flex"
		justify-content="center"
		align-items="center"
	>
		<Icon
			category="io"
			icon={IoMdCheckmark}
			width="40px"
			height="40px"
			background="#bec3ca"
			border-radius="100%"
			color="--darkL1"
		/>
	</Box>, iconsTypeTwo:
		<Box
			height="30px"
			sm-height="40px"
			md-height="50px"
			lg-height="60px"
			display="flex"
			justify-content="center"
			align-items="center"
		>
			<Icon
				category="io"
				icon={IoIosClose}
				width="40px"
				height="40px"
				background="#393341"
				border-radius="100%"
				color="#c1cbd1"
				opacity=".25"
				size="30px"
			/>
		</Box>,
	iconsTypeThree: <Box
		height="30px"
		sm-height="40px"
		md-height="50px"
		lg-height="60px"
		display="flex"
		justify-content="center"
		align-items="center"
	>
		<Icon
			category="io"
			icon={IoIosCheckmark}
			background="#393341"
			border-radius="100%"
			width="40px"
			height="40px"
			color="--indigo"
		/>
	</Box>
}]

const StyledCTA = styled(Button)`
  transition: all 0.3s ease; // Smooth transition for hover effects

  &:hover {
    background: #3e3a47; // Slightly lighter shade for hover
    color: #ffffff;
    transform: translateY(-3px); // Subtle lift effect
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Soft shadow for depth
    border-color: --color-lighterIndigo; // Optional: change border color
  }
`;

// const StyledLink = styled(Link)`
//   color: white; // Adjust as needed
//   font-family: Arial, sans-serif; // Adjust as needed
//   text-decoration: none;
//   background: linear-gradient(135deg, #6C23A0, #333366);
//   border-radius: 10px; // Slightly increased for a larger look
//   padding: 19px 32px; // Increased padding for a larger button
//   font-size: 21px; // Larger font size
//   text-align: center;
//   margin: -20px 0px 42px 0px;
//   position: static;
//   transition: all 0.3s ease;
//   transform-origin: center; // Add this line

//   &:hover {
//     opacity: 0.9;
//     transform: scale(1.05);
//     box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
//     border: 2px solid #fff;
//   }
// `;



export default ((props) => {
	const [showPopup, setShowPopup] = useState(false);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			const carouselElement = document.getElementById('#custom-carosual-setting');
			if (carouselElement) {
				const slidediv = carouselElement.children[0];
				if (slidediv) {
					for (let i = 0; i < 11; i++) {
						try {
							const slideChildren = slidediv.children[i];
							if (slideChildren) {
								const finalChild = slideChildren.children;
								if (finalChild && finalChild.length > 0) {
									if (slideChildren instanceof HTMLElement) {
										const divElement = document.createElement('div');
										divElement.style.background = `url(${allImages[i]})`;
										divElement.style.filter = 'blur(20px)'; // Apply blur effect
										divElement.style.transform = 'scale(2.1)'; // Apply zoom effect, 1.1 means 110% of the original size
										divElement.style.transformOrigin = 'center'; // Define the point around which the element is scaled
										divElement.style.height = '100%';
										divElement.style.width = '100%';
										divElement.style.backgroundPosition = 'center';
										divElement.style.backgroundRepeat = 'no-repeat';
										divElement.style.backgroundSize = 'cover';
										slideChildren.appendChild(divElement);
										console.count();
									}
								}
							}
						} catch (error) {
							console.error(`Error in iteration ${i}: ${error.message}`);
							continue;
						}
					}
				}
			}
		}, 2000);
		return () => clearTimeout(timeoutId);
	}, []);

	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Instant Wedding Floral Quotes | Flower Boom Dallas
			</title>
			<meta name={"description"} content={"Get an instant floral quote tailored for your wedding with Flower Boom Dallas. Save time, visualize your dream, and turn it into a floral reality."} />
			<meta property={"og:title"} content={"Flower Boom Dallas: Your Dream Wedding, One Floral Design at a Time"} />
			<meta property={"og:description"} content={"Turn your wedding into a floral masterpiece with Flower Boom Dallas. Get an instant estimate and start your journey to a magical wedding today!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/tall-centerpiece-white-pink_51_2055487-169163449046087.webp?v=2023-09-06T05:42:29.723Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<StructuredData />
		<Section sm-padding="8px 0 8px 0" quarkly-title="Header-5" padding="10px 0 10px 0">
			<Override slot="SectionContent" flex-direction="row" justify-content="space-between" />
			<Box
				display="flex"
				padding="12px 0"
				justify-content="space-between"
				align-items="flex-start"
				flex-direction="row"
				width="50%"
				sm-width="70%"
				md-width="50%"
				lg-width="50%"
				sm-align-items="center"
				sm-flex-direction="row"
				sm-justify-content="flex-start"
				md-justify-content="flex-start"
			>
				<LinkBox flex-direction="row" href="/index" display="flex" grid-gap="12px">
					<Image src="https://uploads.quarkly.io/6457b971d28fe100213a0f35/images/flower-logo-template.svg?v=2023-08-06T19:34:51.878Z" display="block" width="36px" height="36px" />
					<Text
						margin="0"
						md-margin="0px 0 0px 0"
						text-align="left"
						font="--headline3"
						md-font="--headline4"
						sm-font="--headline4"

						sm-margin="0px 0 0px 0"
						display="block"
					>
						Flower Boom Dallas
					</Text>
				</LinkBox>
			</Box>
			<Components.QuarklycommunityKitMobileSidePanel
				menuPosition="full"
				breakpoint="lg"
				width="50%"
				sm-width="30%"
				md-width="50%"
				lg-width="50%"
			>
				<Override slot="Children" md-display="flex" />
				<Override
					slot="Content"
					padding="0px 0px 0px 0px"
					background="rgba(255, 255, 255, 0)"
					lg-background="#ffffff"
					lg-margin="0px 0px 0px 0px"
				/>
				<Override
					slot="Button Text"
					font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					text-transform="uppercase"
					letter-spacing="1px"
					sm-font="normal 600 14px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					sm-margin="0px 2px 0px 0px"
					lg-margin="0px 0px 0px 0px"
				/>
				<Override
					slot="Button Icon :closed"
					category="fi"
					icon={FiMenu}
					size="32px"
					padding="5px 7px 5px 7px"
					border-radius="50px"
				/>
				<Override
					slot="Button Icon"
					width="28px"
					height="28px"
					category="ai"
					icon={AiOutlineMenu}
					color="--dark"
					size="24px"
					lg-width="32px"
					lg-height="32px"
				/>
				<Override
					slot="Cross"
					lg-width="32px"
					lg-height="32px"
					size="32px"
					top="24px"
					right="24px"
				/>
				<Box
					align-items="center"
					lg-justify-content="center"
					lg-align-items="flex-start"
					justify-content="flex-end"
					display="flex"
					lg-flex-direction="column"
					lg-margin="0px auto 0px auto"
					lg-min-width="300px"
					lg-max-width="1280px"
					lg-width="90%"
					lg-padding="24px 0px 48px 0px"
				>
					<Box
						display="none"
						lg-width="100%"
						lg-margin="0px 0px 24px 0px"
						lg-display="flex"
						lg-padding="12px 0px 12px 0px"
					>
						<LinkBox flex-direction="row" href="/index" display="flex" grid-gap="12px">
							<Image src="https://uploads.quarkly.io/6457b971d28fe100213a0f35/images/flower-logo-template.svg?v=2023-08-06T19:34:51.878Z" display="block" width="36px" height="36px" />
							<Text
								margin="0"
								md-margin="0px 0 0px 0"
								text-align="left"
								font="--lead"
								sm-margin="0px 0 0px 0"
								display="block"
								lg-font="--lead"
							>
								Company
							</Text>
						</LinkBox>
					</Box>
					<Components.QuarklycommunityKitMenu
						display="flex"
						filterMode="exclude"
						filterPages="/index"
						grid-gap="12px"
						lg-flex-direction="column"
						lg-padding="6px 0px 6px 0px"
						lg-margin="0px 0px 24px 0px"
						align-items="center"
						flex-wrap="wrap"
						overflow-x="visible"
						overflow-y="visible"
						lg-align-items="flex-start"
					>
						<Override
							slot="link"
							color="--darkL2"
							hover-color="--primary"
							font="--base"
							text-decoration-line="initial"
							transition="color 0.1s ease 0s"
							lg-font="--lead"
						/>
						<Override
							slot="item-active"
							border-width="0 0 2px 0"
							border-style="solid"
							border-color="--color-darkL2"
							lg-border-width="0 0 0 2px"
						/>
						<Override slot="item" padding="6px 6px 6px 6px" lg-padding="6px 12px 6px 12px" />
						<Override slot="link-active" cursor="default" color="--darkL2" hover-color="--darkL2" />
						<Override slot="link-about" />
					</Components.QuarklycommunityKitMenu>
					{/* <Link
						font="--lead"
						text-decoration-line="initial"
						margin="0px 0px 0px 16px"
						background="linear-gradient(135deg, #6C23A0, #333366)"
						border-radius="8px"
						href="https://flowerboomdallas.hbportal.co/schedule/63b9f3745a2c481b60b04b98"
						color="#ffffff"
						padding="6px 12px 7px 12px"
						lg-margin="0px 0px 24px 0px"
						lg-padding="12px 18px 13px 18px"
						lg-font="--lead"
						align-self="center"
					>
						Book A Free Consultation
					</Link> */}
					<GradientBtn
						icon={<CalendarOutlined color='white' />}
						href="https://flowerboomdallas.hbportal.co/schedule/63b9f3745a2c481b60b04b98"
						text="Book A Free Consultation"
					/>
					<Box
						width="25%"
						display="flex"
						lg-width="100%"
						lg-flex-direction="column"
						lg-align-items="flex-start"
						lg-display="flex"
						justify-content="space-around"
						align-items="center"
						flex-wrap="wrap"
						lg-margin="32px 0px 0px 0px"
						margin-left="10px"
					>
						<SocialMedia
							instagram="https://www.instagram.com/flowerboomdallasevents"
							margin="0px 0px 0px 0px"
							facebook="https://www.facebook.com/flowerboomdallas"
							youtube="https://www.youtube.com/watch?v=SgWkEa1rxzE&ab_channel=FlowerBoomDallas"
							lg-display="flex"
							lg-grid-gap="12px"
						>
							<Override slot="link-twitter" margin="0px 0px 0px 5px">
								<div />
							</Override>
							<Override
								slot="link"
								border-radius="50%"
								background="transparent"
								hover-color="--light"
								display="flex"
								margin="0 5px 0 5px"
								padding="5x 5px 5px 5px"
								width="32px"
								height="32px"
								align-items="center"
								justify-content="center"
							/>
							<Override slot="icon" size="32px" border-radius="50px" color="--grey" />
							<Override slot="link-facebook" margin="0px 5px 0px 0px">
								<div />
							</Override>
						</SocialMedia>
					</Box>
				</Box>
			</Components.QuarklycommunityKitMobileSidePanel>
		</Section>
		{/* <Section
			background="radial-gradient(90% 90% at 80% 50%, rgba(21, 31, 42, 0.4) 0%, #04080C 100%),url(https://images.unsplash.com/photo-1629046133174-b5c0d944640b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000)"
			min-height="100vh"
			lg-padding="120px 0 88px 0"
			md-padding="140px 0 96px 0"
			sm-padding="60px 0 96px 0"
			quarkly-title="Hero-22"
			padding="88px 0"
			position="static"
			style={{
				"backgroundSize": "cover",
				"backgroundRepeat": "no-repeat",
				"backgroundPosition": "center center"
			}}
			display="flex"
			overflow-x="auto"
			overflow-y="auto"
			flex-direction="row"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="100%"
				flex-direction="row"
				align-items="flex-end"
				lg-justify-content="center"
				display="flex"
				lg-align-items="center"
				justify-content="space-between"
				lg-flex-direction="column"
			>

				<Text
					margin="0px"
					as="h1"
					color="--light"
					width="60%"
					md-width="100%"
					lg-text-align="center"
					lg-margin="0px 0px 20px 0px"
					font="--headline1"
					sm-font="normal 700 42px/1.2 'Source Sans Pro', sans-serif"
					display="block"
					flex="1 0 auto"
					order="0"
					align-self="center"
				>
					Flower Boom Dallas: Instant Wedding Floral Quotes
				</Text>
				<Text
					lg-text-align="center"
					margin="0px"
					color="#FFFFFF"
					font="--lead"
					width="30%"
					lg-width="100%"
					as="h3"
				>
					Discovering us isn't by chance. It's the start of your amazing wedding floral experience with Flower Boom Dallas.
				</Text>
			</Box>
		</Section> */}
		{/* <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" font="16px ">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" font="16px --fontFamily-sans" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="60%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					as="h2"

				>
					Your Dream Wedding with Our Flowers
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--lead"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Picture a wedding aisle, not just decorated, but narrated with flowers that echo your love story.
					We don't just arrange flowers; we curate memories.
				</Text>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="40%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						1
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left" as="h3">
							Tell Us Your Dream
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="left">
							Your dream wedding starts with a single step: filling out our intuitive form. Share your vision, your hopes,
							and your love story. We want to know what makes your heart soar so we can elevate your special day to new
							heights.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						2
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left" as="h3">
							Get a Quick Price. In Seconds!
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="left">
							Why wait when love is in the air? As soon as you hit 'submit,' our system calculates a personalized estimate
							for you. No delays, no fuss. Just a transparent look at what we can create together.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 0px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						3
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left" as="h3">
							Making Dreams Real
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="left">
							Once you've got your estimate, we'll connect for a heart-to-heart conversation. We'll discuss the intricate
							details, tweak the plans as needed, and then seal the deal with a contract. It's not just business; it's a
							partnership in creating something beautiful.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section> */}
		{/* <div style={{
			"display": "flex",
			"justifyContent": "center",
			"alignItems": "center"
		}}>
			<StyledLink
				href="#"
				onClick={(e) => {
					props.history.push('instant-wedding-quote');
				}}
			>
				Get My Instant Quote
			</StyledLink>
			<GradientBtn
				text="Get Started Now"
				onClick={(e) => {
					e.preventDefault();
					setShowPopup(true);
					document.body.style.overflow = 'hidden'; // Disable scrolling on the main page
				}}
				className="mb-4"
			/>

			{showPopup && <Popup onClose={() => {
				setShowPopup(false);
				document.body.style.overflow = 'auto'; // Enable scrolling on the main page
			}} />}
		</div> */}
		<div style={{ marginBottom: '80px', marginTop: '20px' }}>
			<HomeImageGallery {...props} />
		</div>
		<ChoosePathSection {...props} />
		{/* <ImageGallery
			galleryID='my-test-gallery'
			images={[
				{
					slot: "Slide Image,Slide Image 1",
					largeURL:
						"https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/img-6307_51_2055487-168903822678687.webp?v:2023-09-06T05:42:29.542Z",
					thumbnailURL:
						"https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/img-6307_51_2055487-168903822678687.webp?v:2023-09-06T05:42:29.542Z",
					"object-fit": "contain",
					height: 1281,
					width: 1920,
				},
				{
					slot: "Slide Image",
					largeURL:
						"https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/purple-jasmine-centerpiece_51_2055487-169163449611963.webp?v=2023-09-06T05:42:29.529Z",
					thumbnailURL:
						"https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/purple-jasmine-centerpiece_51_2055487-169163449611963.webp?v=2023-09-06T05:42:29.529Z",
					width: 507,
					height: 713,
					"object-fit": "contain",
				},
				{
					slot: "Slide Image 2",
					largeURL: "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/img-5348_51_2055487-166881243955612.webp?v=2023-09-06T05:42:29.542Z",
					thumbnailURL: "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/img-5348_51_2055487-166881243955612.webp?v=2023-09-06T05:42:29.542Z",
					height: 1326,
					width: 1062,
					"object-fit": "contain",
				},
				{
					slot: "Slide Image 3",
					largeURL: "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/img-5217_51_2055487-166881243966053.webp?v=2023-09-06T05:42:29.560Z",
					thumbnailURL: "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/img-5217_51_2055487-166881243966053.webp?v=2023-09-06T05:42:29.560Z",
					height: 1080,
					width: 1080,
					"object-fit": "contain",
				},
				{
					slot: "Slide Image 4",
					largeURL: "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/tall-centerpiece-white-pink_51_2055487-169163449046087.webp?v=2023-09-06T05:42:29.723Z",
					thumbnailURL: "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/tall-centerpiece-white-pink_51_2055487-169163449046087.webp?v=2023-09-06T05:42:29.723Z",
					width: 636,
					height: 636,
					"object-fit": "contain",
				},
				{
					slot: "Slide Image 6",
					largeURL: "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/brianneivan-wedding-633_51_2055487-169155192853041.webp?v=2023-09-06T05:42:29.553Z",
					thumbnailURL: "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/brianneivan-wedding-633_51_2055487-169155192853041.webp?v=2023-09-06T05:42:29.553Z",
					width: 884,
					height: 1326,
					"object-fit": "contain",
				},
				{
					slot: "Slide Image 7",
					largeURL: "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/green-white-aisle_51_2055487-169163447888235.webp?v=2023-09-06T05:42:29.540Z",
					thumbnailURL: "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/green-white-aisle_51_2055487-169163447888235.webp?v=2023-09-06T05:42:29.540Z",
					width: 794,
					height: 613,
					"object-fit": "contain",
				},
				{
					slot: "Slide Image 8",
					largeURL: "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/img-5341_51_2055487-166881513117265.webp?v=2023-09-06T05:42:29.541Z",
					thumbnailURL: "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/img-5341_51_2055487-166881513117265.webp?v=2023-09-06T05:42:29.541Z",
					width: 1060,
					height: 1326,
					"object-fit": "contain",
				},
				{
					slot: "Slide Image 9",
					largeURL: "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/balls-centerpiece-tall_51_2055487-169163447096918.webp?v=2023-09-06T05:42:29.533Z",
					thumbnailURL: "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/balls-centerpiece-tall_51_2055487-169163447096918.webp?v=2023-09-06T05:42:29.533Z",
					width: 780,
					height: 510,
					"object-fit": "contain",
				},
			]}
		/> */}
		{/* <div style={{
			"display": "flex",
			"justifyContent": "center",
			"alignItems": "center",
			"padding": "60px 0 0 0"
		}}>
			<StyledLink
				href="#"
				onClick={(e) => {
					props.history.push('instant-wedding-quote');
				}}
			>
				Get My Instant Quote
			</StyledLink>

			{showPopup && <Popup onClose={() => {
				setShowPopup(false);
				document.body.style.overflow = 'auto'; // Enable scrolling on the main page
			}} />}
		</div> */}
		<Section
			padding="120px 0 0 0"
			background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/abstract-textured-backgound-2.jpg?v=2023-05-01T17:22:07.340Z) 0% 0% /cover repeat scroll padding-box"
			lg-margin="0 0 0 0"
			lg-padding="80px 0 80px 0"
			quarkly-title="Price-7"
		>
			<Override
				slot="SectionContent"
				width="100%"
				max-width="none"
				min-width="none"
				align-items="center"
				justify-content="flex-start"
				lg-padding="0px 20px 0px 20px"
			/>
			<Box
				min-width="300px"
				min-height="100px"
				max-width="1280px"
				display="flex"
				flex-direction="column"
				align-items="center"
				justify-content="flex-start"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="--headline2"
					text-align="center"
					md-margin="0px 0px 50px 0px"
					width="700p"
					align-self="center"
					color="--light"
					sm-margin="0px 0px 30px 0px"
					as="h2"
				>
					Flower Packages for All Weddings
				</Text>
				<Text
					margin="0px 0px 120px 0px"
					text-align="center"
					font="--lead"
					width="600px"
					align-self="center"
					md-width="100%"
					color="#c1cbd1"
					md-margin="0px 0px 60px 0px"
					sm-margin="0px 0px 40px 0px"
					lg-margin="0px 0px 80px 0px"
				>
					Each package is designed to suit weddings of different sizes and styles, ensuring that your special day is as
					beautiful as you've always imagined.
				</Text>
				<Box min-width="100px" min-height="100px" lg-width="100%">
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						lg-margin="0px 0 0px 0"
						md-flex-direction="column"
						border-width="1px"
						lg-flex-direction="column"
						justify-content="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							padding="35px 35px 50px 35px"
							border-color="#26212d"
							border-radius="15px"
							box-shadow="0 4px 5px -1px rgba(0, 0, 0, 0.04)"
							display="flex"
							flex-direction="column"
							background="#1d1724"
							border-width="1px"
							border-style="solid"
							position="relative"
							z-index="1"
							width="33%"
							lg-width="100%"
							lg-margin="0px 0px 50px 0px"
							lg-padding="40px 40px 40px 40px"
							lg-align-items="stretch"
							sm-padding="30px 20px 30px 20px"
							sm-margin="0px 0px 30px 0px"
						>
							<Box
								min-width="100px"
								min-height="100px"
								sm-display="flex"
								sm-flex-direction="column"
								sm-align-items="center"
							>
								<Text
									margin="0px 0px 8px 0px"
									font="--headline3"
									color="--light"
									lg-margin="0px 0px 20px 0px"
									sm-text-align="center"
									sm-margin="0px 0px 10px 0px"
									as="h3"
								>
									Intimate Affair Package
								</Text>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0px 0px 8px 0px"
									sm-flex-direction="column"
									flex-wrap="wrap"
								>
									<Text margin="0px 0px 10px 0px" font="normal 400 18px/1.5 --fontFamily-sans" color="#c1cbd1">
										<Span
											font="normal 700 52px/1.2 --fontFamily-sans"
											overflow-wrap="normal"
											word-break="normal"
											white-space="normal"
											text-indent="0"
											text-overflow="clip"
											hyphens="manual"
											user-select="auto"
											pointer-events="auto"
											color="--light"
											margin="0px 7px 0px 0px"
										>
											$1,499
										</Span>
										{" "}
									</Text>
									<Text
										margin="0px 0px 10px 0px"
										font="normal 400 18px/1.5 --fontFamily-sans"
										color="#c1cbd1"
										sm-text-align="center"
										align-self="auto"
										order="0"
										flex="1 1 0%"
									>
										Weddings with up to 50 guests
									</Text>
								</Box>
								<a href="https://flowerboomdallas.com/cart/44080518463727:1" target="_">
									<StyledCTA
										background="#2f2b36"
										color="--light"
										border-color="--color-indigo"
										border-radius="4px"
										font="normal 400 17px/1.5 --fontFamily-sansHelvetica"
										margin="0px 0px 25px 0px"
										sm-margin="0px 0px 25px 0px"
									>
										Book Now
									</StyledCTA>
								</a>

							</Box>
							<Box min-width="100px" min-height="100px">
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									border-width="0 0 1px 0"
									border-style="dashed"
									border-color="#393341"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#c1cbd1">
										Bridal Bouquet
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									border-width="0 0 1px 0"
									border-style="dashed"
									border-color="#393341"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#c1cbd1">
										4 Bridesmaid Bouquets
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
									border-width="0 0 1px 0"
									border-style="dashed"
									border-color="#393341"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#c1cbd1">
										8 Boutonnieres
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
									border-width="0 0 1px 0"
									border-style="dashed"
									border-color="#393341"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#c1cbd1">
										2 Wrist Corsages
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
									border-width="0 0 1px 0"
									border-style="dashed"
									border-color="#393341"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#c1cbd1">
										5 Table Centerpieces
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
									border-width="0 0 1px 0"
									border-style="dashed"
									border-color="#393341"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#c1cbd1">
										6 Pew Markers
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
									border-width="0 0 1px 0"
									border-style="dashed"
									border-color="#393341"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#c1cbd1">
										Delivery & Setup
									</Text>
								</Box>
							</Box>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="35px 35px 60px 35px"
							border-width="1px"
							border-style="solid"
							border-color="#bec3ca"
							border-radius="15px"
							box-shadow="0 4px 5px -1px rgba(0, 0, 0, 0.04)"
							display="flex"
							flex-direction="column"
							background="--color-light"
							position="relative"
							z-index="2"
							margin="-40px -5px -40px -5px"
							width="33%"
							lg-width="100%"
							lg-margin="0px 0px 50px 0"
							lg-padding="40px 40px 40px 40px"
							lg-align-items="stretch"
							sm-padding="30px 20px 30px 20px"
							sm-margin="0px 0px 30px 0"
						>
							<Text
								margin="0px 0px 8px 0px"
								font="--headline3"
								color="--darkL1"
								lg-margin="0px 0px 20px 0px"
								sm-text-align="center"
								sm-margin="0px 0px 10px 0px"
								as="h2"
							>
								Classic Elegance Package
							</Text>
							<Box
								min-width="100px"
								min-height="100px"
								sm-display="flex"
								sm-flex-direction="column"
								sm-align-items="center"
								sm-justify-content="center"
							>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0px 0px 8px 0px"
									sm-flex-direction="column"
									flex-wrap="wrap"
								>
									<Text margin="0px 0px 10px 0px" font="normal 400 18px/1.5 --fontFamily-sans" color="#c1cbd1">
										<Span
											font="normal 700 52px/1.2 --fontFamily-sans"
											overflow-wrap="normal"
											word-break="normal"
											white-space="normal"
											text-indent="0"
											text-overflow="clip"
											hyphens="manual"
											user-select="auto"
											pointer-events="auto"
											color="--darkL1"
											margin="0px 7px 0px 0px"
										>
											$3,499
										</Span>
										{" "}
									</Text>
									<Text
										margin="0px 0px 10px 0px"
										font="normal 400 18px/1.5 --fontFamily-sans"
										color="#4f5457"
										sm-text-align="center"
										flex="1 1 0%"
									>
										Weddings with 50-150 guests
									</Text>
								</Box>
								<a href="https://flowerboomdallas.com/cart/44080538878191:1" target="_">
									<StyledCTA
										background="--color-indigo"
										color="--light"
										border-color="--color-light"
										border-radius="4px"
										font="normal 400 17px/1.5 --fontFamily-sansHelvetica"
										margin="0px 0px 25px 0px"
										sm-margin="0px 0px 25px 0px"
									>
										Book Now
									</StyledCTA>
								</a>

							</Box>
							<Box min-width="100px" min-height="100px" font="--base">
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									border-width="0 0 1px 0"
									border-style="dashed"
									border-color="#bec3ca"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#4f5457">
										Bridal Bouquet
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									border-width="0 0 1px 0"
									border-style="dashed"
									border-color="#bec3ca"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#4f5457">
										6 Bridesmaid Bouquets
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
									border-width="0 0 1px 0"
									border-style="dashed"
									border-color="#bec3ca"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#4f5457">
										10 Boutonnieres
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
									border-width="0 0 1px 0"
									border-style="dashed"
									border-color="#bec3ca"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#4f5457">
										4 Corsages
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
									border-width="0 0 1px 0"
									border-style="dashed"
									border-color="#bec3ca"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#4f5457">
										1 Floral Arch
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#4f5457">
										8 Pew Markers
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#4f5457">
										10 Table Centerpieces
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#4f5457">
										Petals for Aisle
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#4f5457">
										Cake Flowers
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#4f5457">
										Delivery & Setup
									</Text>
								</Box>
							</Box>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="35px 35px 50px 35px"
							border-color="#26212d"
							border-radius="15px"
							box-shadow="0 4px 5px -1px rgba(0, 0, 0, 0.04)"
							display="flex"
							flex-direction="column"
							background="#1d1724"
							margin="45px 0 3 0"
							border-width="1px"
							border-style="solid"
							position="relative"
							z-index="1"
							width="33%"
							lg-width="100%"
							lg-padding="40px 40px 40px 40px"
							lg-align-items="stretch"
						>
							<Box
								min-width="100px"
								min-height="100px"
								sm-display="flex"
								sm-flex-direction="column"
								sm-align-items="center"
							>
								<Text margin="0px 0px 8px 0px" font="--headline3" color="--light" lg-margin="0px 0px 20px 0px" as="h2">
									Grand Celebration Package
								</Text>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0px 0px 8px 0px"
									sm-flex-direction="column"
									flex-wrap="wrap"
								>
									<Text margin="0px 0px 10px 0px" font="normal 400 18px/1.5 --fontFamily-sans" color="#c1cbd1">
										<Span
											font="normal 700 52px/1.2 --fontFamily-sans"
											overflow-wrap="normal"
											word-break="normal"
											white-space="normal"
											text-indent="0"
											text-overflow="clip"
											hyphens="manual"
											user-select="auto"
											pointer-events="auto"
											color="--light"
											margin="0px 7px 0px 0px"
										>
											$9,999
										</Span>
										{" "}
									</Text>
									<Text
										margin="0px 0px 10px 0px"
										font="normal 400 18px/1.5 --fontFamily-sans"
										color="#c1cbd1"
										sm-text-align="center"
										flex="1 1 0%"
									>
										Weddings with more than 150 guests
									</Text>
								</Box>
								<a href="https://flowerboomdallas.com/cart/44080551887087:1" target="_">
									<StyledCTA
										background="#2f2b36"
										color="--light"
										border-color="--color-indigo"
										border-radius="4px"
										font="normal 400 17px/1.5 --fontFamily-sansHelvetica"
										margin="0px 0px 25px 0px"
									>
										Book Now
									</StyledCTA>
								</a>
							</Box>
							<Box min-width="100px" min-height="100px">
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									border-width="0 0 1px 0"
									border-style="dashed"
									border-color="#393341"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#c1cbd1">
										Bridal Bouquet
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									border-width="0 0 1px 0"
									border-style="dashed"
									border-color="#393341"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#c1cbd1">
										6 Bridesmaid Bouquets
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
									border-width="0 0 1px 0"
									border-style="dashed"
									border-color="#393341"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#c1cbd1">
										8 Boutonnieres
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#c1cbd1">
										4 Corsages
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#c1cbd1">
										1 Floral Arch or Backdrop
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#c1cbd1">
										1 Floral Centerpiece for the Head Table
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#c1cbd1">
										20 Table Centerpieces
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#c1cbd1">
										Petals for Aisle
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#c1cbd1">
										Cake Flowers
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#c1cbd1">
										Floral Chandelier or Hanging Installation
									</Text>
								</Box>
								<Box
									min-width="10px"
									min-height="10px"
									display="flex"
									align-items="center"
									margin="0 0 6px 0"
								>
									<Icon
										category="io"
										icon={IoIosCheckmark}
										size="32px"
										color="--indigo"
										margin="0px 8px 0px 0px"
									/>
									<Text margin="0px 0px 5px 0px" font="normal 400 18px/1.2 --fontFamily-sansHelvetica" color="#c1cbd1">
										Delivery & Setup
									</Text>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="150px"
				background="#ffffff"
				pointer-events="none"
				width="100%"
				lg-display="none"
			/>
		</Section >
		<div style={{
			"display": "flex",
			"justifyContent": "center",
			"alignItems": "center"
		}}>
			<Link
				href="#"
				onClick={(e) => {
					e.preventDefault();
					setShowPopup(true);
					document.body.style.overflow = 'hidden'; // Disable scrolling on the main page
				}}
				text-decoration-line="initial"
				color="--light"
				font="--lead"
				background="linear-gradient(135deg, #6C23A0, #333366)"
				border-radius="8px"
				padding="11px 24px 12px 24px"
				text-align="center"
				style={{
					"transition": "all 0.3s ease",
					":hover": {
						"opacity": 0.9,
						"transform": "scale(1.05)",
						"boxShadow": "0px 4px 8px rgba(0, 0, 0, 0.1)", // New hover effect
						"border": "2px solid #fff" // New hover effect
					}
				}}
				margin="-10px 0px 25px 0px"
				position="static"
			>
				Get Your Personal Instant Quote
			</Link>
			{/* <GradientBtn
				text="Get Started Now"
				onClick={(e) => {
					e.preventDefault();
					setShowPopup(true);
					document.body.style.overflow = 'hidden'; // Disable scrolling on the main page
				}}
				className="mb-4"
			/> */}

			{showPopup && <Popup onClose={() => {
				setShowPopup(false);
				document.body.style.overflow = 'auto'; // Enable scrolling on the main page
			}} />}
		</div>
		<Section padding="90px 0 120px 0" quarkly-title="Partners-3">
			<Override slot="SectionContent" align-items="center" />
			<Text margin="0px 0px 20px 0px" text-align="center" font="--headline1" color="rgba(52, 12, 80, 0.85)">
				Clients and partners
			</Text>
			<Image
				src="https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-07T13:48:29.241Z"
				display="block"
				width="150px"
				text-align="center"
				margin="0px 0px 20px 0px"
				srcSet="https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-07T13%3A48%3A29.241Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-07T13%3A48%3A29.241Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-07T13%3A48%3A29.241Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-07T13%3A48%3A29.241Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-07T13%3A48%3A29.241Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-07T13%3A48%3A29.241Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-07T13%3A48%3A29.241Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
			<Text
				margin="0px 0px 70px 0px"
				text-align="center"
				font="normal 400 22px/1.5 --fontFamily-serifGeorgia"
				color="rgba(52, 12, 80, 0.85)"
				sm-margin="0px 0px 50px 0px"
			>
				Our valued partnerships have crafted unforgettable floral moments for Dallas weddings.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(5, 1fr)"
				grid-gap="40px 44px"
				max-width="100%"
				align-items="center"
				width="100%"
				lg-grid-template-columns="repeat(3, 1fr)"
				lg-grid-gap="36px 72px"
				sm-grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
			>
				<a href="https://virginhotels.com/dallas/" target="_blank" rel="noopener noreferrer">
					<Image
						src="https://cdn.shopify.com/s/files/1/0466/6893/9420/files/virginhotels.png"
						display="block"
						width="100%"
						srcSet="https://cdn.shopify.com/s/files/1/0466/6893/9420/files/virginhotels.png"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</a>
				<a href="https://www.bellaterravenue.com/" target="_blank" rel="noopener noreferrer">
					<Image
						src="https://cdn.shopify.com/s/files/1/0466/6893/9420/files/Bella_Terra_logo.png"
						display="block"
						width="100%"
						srcSet="https://cdn.shopify.com/s/files/1/0466/6893/9420/files/Bella_Terra_logo.png"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</a>
				<a href="https://www.maggianos.com/locations/texas/dallas/205-northpark-center/" target="_blank" rel="noopener noreferrer">
					<Image
						src="https://cdn.shopify.com/s/files/1/0466/6893/9420/files/Maggiano_s_logo.png?v=1694936163"
						display="block"
						width="100%"
						srcSet="https://cdn.shopify.com/s/files/1/0466/6893/9420/files/Maggiano_s_logo.png?v=1694936163"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</a>
				<a href="https://stgabriel.org/" target="_blank" rel="noopener noreferrer">
					<Image
						src="https://cdn.shopify.com/s/files/1/0466/6893/9420/files/St_Gabriel_The_Archangel_Catholic_Community_logo.png?v=1694936201"
						display="block"
						width="100%"
						srcSet="https://cdn.shopify.com/s/files/1/0466/6893/9420/files/St_Gabriel_The_Archangel_Catholic_Community_logo.png?v=1694936201"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</a>
				<a href="https://waltersweddingestates.com/" target="_blank" rel="noopener noreferrer">
					<Image
						src="https://cdn.shopify.com/s/files/1/0466/6893/9420/files/WaltersWeddingEstatesLogo_1.png?v=1694936466"
						display="block"
						width="100%"
						srcSet="https://cdn.shopify.com/s/files/1/0466/6893/9420/files/WaltersWeddingEstatesLogo_1.png?v=1694936466"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</a>
				<a href="https://www.rosewoodhotels.com/en/mansion-on-turtle-creek-dallas/dining/mansion-restaurant" target="_blank" rel="noopener noreferrer">
					<Image
						src="https://cdn.shopify.com/s/files/1/0466/6893/9420/files/Rosewood_Mansion_on_Turtle_Creek.png"
						display="block"
						width="100%"
						srcSet="https://cdn.shopify.com/s/files/1/0466/6893/9420/files/Rosewood_Mansion_on_Turtle_Creek.png"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</a>
				<a href="https://www.montclairdfw.com/" target="_blank" rel="noopener noreferrer">
					<Image
						src="https://cdn.shopify.com/s/files/1/0466/6893/9420/files/Montclair_Wedding_Event_Venue.png"
						display="block"
						width="100%"
						srcSet="https://cdn.shopify.com/s/files/1/0466/6893/9420/files/Montclair_Wedding_Event_Venue.png"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</a>
				<a href="https://www.watermark.org/" target="_blank" rel="noopener noreferrer">
					<Image
						src="https://cdn.shopify.com/s/files/1/0466/6893/9420/files/Watermark_Community_Church_logo.png"
						display="block"
						width="100%"
					/>
				</a>
				<a href="https://springsvenue.com/mckinney/" target="_blank" rel="noopener noreferrer">
					<Image
						src="https://cdn.shopify.com/s/files/1/0466/6893/9420/files/The_Springs_-_Wedding_Event_Venues.png"
						display="block"
						width="100%"
					/>
				</a>
				<a href="https://www.hilton.com/en/hotels/dfwanhh-hilton-anatole/events/weddings/" target="_blank" rel="noopener noreferrer">
					<Image
						src="https://cdn.shopify.com/s/files/1/0466/6893/9420/files/Hilton_Anatole_Dallas.png"
						display="block"
						width="100%"
					/>
				</a>
			</Box>
		</Section>

		<Section
			padding="120px 0 0 0"
			background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/abstract-textured-backgound-2.jpg?v=2023-05-01T17:22:07.340Z) 0% 0% /cover repeat scroll padding-box"
			lg-padding="80px 0 80px 0"
			sm-padding="30px 0 30px 0"
			lazy-load
			pointer-events="auto"
		>
			<Override slot="SectionContent" />
			<Box
				margin="0px 0px 40px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 32px 0px"
				display="flex"
				flex-direction="row"
				align-items="center"
				sm-margin="0px 0px 10px 0px"
				justify-content="space-between"
				lg-align-items="center"
				lg-flex-direction="column"
				lg-justify-content="center"
			>
				<Text
					margin="0px 32px 0px 0px"
					font="--headline1"
					color="#ffffff"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-margin="0px 0px 12px 0px"
					flex="0 0 auto"
					as="h2"
				>
					Why Choose Us?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--lightD2"
					text-align="left"
					width="50%"
					md-width="100%"
					lg-width="100%"
					lg-text-align="center"
				>
					Our valued floral artists have crafted unforgettable floral moments for Dallas weddings. Here's how we stand out
					from the competition.
				</Text>
			</Box>
			<Box width="100%">
				<Table
				>
					<tbody>
						<tr>
							<th style={cellStyle}></th>
							<th
								style={{
									...lightCellStyle,
									...{ borderTopRightRadius: "16px", borderTopLeftRadius: "16px", paddingTop: "25px" }
								}}
							>
								<Text
									color="--darkL1"
									font="--headline3"
									lg-text-align="center"
									lg-font="normal 600 20px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
									md-font="normal 500 12px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
									display="flex"
									justify-content="center"
									align-items="center"
								>
									Flower Boom Dallas
								</Text>
							</th>
							<th
								style={{
									...darkCellStyle,
									...{
										borderTopRightRadius: "16px", borderTopLeftRadius: "16px"
										, paddingTop: "25px"
									}
								}}
							>
								<Text
									color="--light"
									font="--headline3"
									lg-text-align="center"
									lg-font="normal 600 20px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
									md-font="normal 500 12px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
									display="flex"
									justify-content="center"
									align-items="center"
								>
									Other Local Shops
								</Text>
							</th>
							<th style={{
								...darkCellStyle,
								...{
									borderTopRightRadius: "16px", borderTopLeftRadius: "16px"
									, paddingTop: "25px"
								}
							}}>
								<Text
									color="--light"
									font="--headline3"
									lg-text-align="center"
									lg-font="normal 600 20px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
									md-font="normal 500 12px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
									display="flex"
									justify-content="center"
									align-items="center"
								>
									DIY Online Kits
								</Text>
							</th>
						</tr>

						{Array(7).fill(1).map((_, rowIndex) => (
							<tr key={rowIndex}>
								{tabelData.map((rowData, colIndex) => (
									<Fragment key={`${rowIndex}-${colIndex}`}>
										<td style={cellStyle}>
											<Text color="--light" font="--headline3" md-font="normal 500 16px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
												{headingArray[rowIndex]}
											</Text>
										</td>
										<td
											style={{
												...lightCellStyle,
												...(rowIndex === 6 && { borderBottomRightRadius: "16px", borderBottomLeftRadius: "16px", })
											}}>
											{rowData.iconsTypeOne}
										</td>
										<td
											style={{
												...darkCellStyle,
												...(rowIndex === 6 && { borderBottomRightRadius: "16px", borderBottomLeftRadius: "16px", })
											}}
										>
											{(rowIndex === 1 || rowIndex === 3) ? rowData.iconsTypeTwo : rowData.iconsTypeThree}
										</td>
										<td
											style={{
												...darkCellStyle,
												...(rowIndex === 6 && { borderBottomRightRadius: "16px", borderBottomLeftRadius: "16px", })
											}}
										>
											{(rowIndex > 1) ? rowData.iconsTypeTwo : rowData.iconsTypeThree}

										</td>
									</Fragment>
								))}
							</tr>
						))}
					</tbody>
				</Table>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--headline2" color="--darkL1" as="h2">
						Your Questions, Answered with Love
					</Text>
					<Text
						margin="0px 0px 70px 0px"
						font="--lead"
						color="#555a5f"
						sm-margin="0px 0px 50px 0px"
						lg-margin="0px 0px 50px 0px"
					>
						Got questions? We've got answers! We love what we do, and we've been doing it for a long time.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="--headline3" color="--darkL1" as="h3">
							What Kinds of Flower Designs Do You Make?
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="#555a5f">
							We make all sorts of flower designs, from dreamy bouquets to elegant table centerpieces. Each one is special and matches your wedding theme.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="--headline3" color="--darkL1" as="h3">
							How Reliable Is the Instant Estimate?
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="#555a5f">
							Our instant estimate is more than just numbers on a screen; it's a promise of transparency and fairness. While
							the final quote may vary based on our detailed discussion, you can trust that our initial estimate is a solid
							starting point.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="--headline3" color="--darkL1" as="h3">
							Where Do You Work?
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="#555a5f">
							We're based in Dallas, Texas, and we're happy to work with couples in and around the city. Wherever your love story takes you, we'll be there to make it even more special with flowers.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="--headline3" color="--darkL1" as="h3">
							What's Next After Getting the Estimate?
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="#555a5f">
							After you get the estimate, we'll get in touch with you. You can choose how we talk—email, text, or a free call. We'll work out all the details to make sure you get exactly what you want.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" font="--headline3" color="--darkL1" as="h3">
							How Do We Lock in Our Wedding Date?
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="#555a5f">
							Once we agree on what you want, you'll get an online invoice and contract. Just read it, sign it, and you're good to go. Your wedding date is now locked in, and it's as easy as clicking a button.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="100px 0 100px 0"
			background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/abstract-textured-backgound-2.jpg?v=2023-05-01T17:22:07.340Z) 0% 0% /cover repeat scroll padding-box"
			quarkly-title="Map"
		>
			<Override
				slot="SectionContent"
				width="100%"
				max-width="1600px"
				align-items="center"
				justify-content="center"
			/>
			<Box
				width="80%"
				min-height="100px"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="--headline1"
					text-align="center"
					align-self="center"
					color="--light"
					as="h2"
				>
					Find Us and Let's Talk Flowers
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					text-align="center"
					font="--lead"
					align-self="center"
					color="#c1cbd1"
				>
					We're on the corner of 75 Central Expressway and Mockingbird Lane.
				</Text>
				<Box
					width="100%"
					display="flex"
					justify-content="center"
				>
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3351.112767186145!2d-96.77443312433347!3d32.86873567362549!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e9fd2f35908ef%3A0x1a24cbda31917115!2sFlower%20Boom%20Dallas!5e0!3m2!1sen!2sus!4v1694988825818!5m2!1sen!2sus"
						width="90%"
						height="450"
						style={{ border: '1px solid #26212d', borderRadius: '15px', boxShadow: '0 4px 5px -1px rgba(0, 0, 0, 0.04)' }}
						allowFullScreen=""
						loading="lazy"
						title='Google Maps'
					></iframe>
				</Box>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://flowerboomdallas.com/"}
			target={"_blank"}
		>
			Made by Flower Boom Dallas LLC
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"64f7edaf098f0700199b65ed"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme >;
});