import { Button } from "antd";
import React from "react";
import styled from "styled-components";

const StyledActionBtn = styled(Button)`
  // width: 100%; // Full width on mobile devices
  // max-width: 200px; // Limit width on larger screens
`;

export function ActionBtn({ onClick, text, type, htmlType }) {
  return (
    <StyledActionBtn
      size='large'
      onClick={onClick}
      type={type}
      htmlType={htmlType}
    >
      {text}
    </StyledActionBtn>
  );
}
