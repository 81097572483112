import { tablet } from "UI/helpers";
import styled, { css } from "styled-components";
import {
  CloseOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
} from "@ant-design/icons";

export const ModalContainer = styled.div`
  font-family: var(--qtheme-fontFamily-sans);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 8px;
`;

export const ModalActionBtnContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
  ${tablet(`
  flex-direction: column;
`)}
  row-gap: 8px;
  & button {
    flex: 1;
    width: 100%;
  }
`;

export const PopupModal = styled.div`
  position: relative;
  width: ${({ modalSize }) => modalSize?.width};
  height: ${({ modalSize }) => modalSize?.height}; // Adjusted height
  maxheight: ${({ modalSize }) =>
    modalSize?.maxHeight}; // Added max height to ensure it doesn't exceed the viewport height
  overflow-y: auto; // Added overflow for scrolling if content exceeds modal height
  max-width: ${({ modalSize }) => modalSize?.maxWidth};
  // background-color: var(--qtheme-color-light);
  background-color: white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  padding: ${({ modalSize }) => modalSize?.padding};
  transition: all 0.9s ease;
  border-radius: 12px;
`;

const modalIconStyles = css`
  font-size: 24px;
  cursor: pointer;
  color: var(--qtheme-color-dark);
`;

export const ModalCloseIcon = styled(CloseOutlined)`
  ${modalIconStyles}
`;

export const ModalFullScreenIcon = styled(FullscreenOutlined)`
  ${modalIconStyles}
`;

export const ModalFullScreenExitIcon = styled(FullscreenExitOutlined)`
  ${modalIconStyles}
`;

export const SectionContainer = styled.div`
  width: 100%;
`;

export const ActionBtnsContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 8px;
`;

export const FormContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(to bottom right, #6c23a0, #d9adf7);
`