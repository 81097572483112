import {
  Col,
  Row,
  Form,
  Select,
  Input,
  Typography,
  Divider,
  Space,
  Button,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useCallback, useRef, useState } from "react";
import { ActionBtn } from "./ActionBtn";
import { ActionBtnsContainer } from "./UI";

const { TextArea } = Input;

export function FloralPreferences({ onPrevClick }) {
  const [items, setItems] = useState([
    "Gold",
    "Silver",
    "Rose Gold",
    "Clear Glass",
    "Mixed Metals",
    "White",
    "Black",
    "Pastel",
    "Vibrant",
    "Monochrome",
  ]);

  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const addBtnRef = useRef(null);

  const onNameChange = useCallback((event) => {
    setName(event.target.value);
  }, []);

  const addItem = useCallback((e) => {
    if (e) e.preventDefault();
    setName((name) => {
      if ((name || "").trim().length < 1) return;
      setItems((ps) => [name, ...ps]);
      return "";
    });
    setTimeout(() => {
      if (inputRef.current && inputRef.current.focus) inputRef.current.focus();
    }, 0);
  }, []);

  const handleKeyDown = useCallback((e) => {
    if (e.key === "Enter" && addBtnRef.current) {
      e.preventDefault();
      e.stopPropagation();
      addBtnRef.current.click();
    }
  }, []);

  return (
    <Row gutter={24} style={{ padding: "0px 4px" }}>
      <Col xs={24} /* md={10} */>
      <Typography.Paragraph style={{ paddingTop: '20px', fontStyle: 'italic' }}>
        Describe your wedding color palette. Don't be afraid to think outside the traditional palettes! 
        Interested in going bold but unsure where to start? Our design team can help create something stunning!
    </Typography.Paragraph>

    <img style={{ paddingBottom: '20px' }}
        src='/images/floral-preferences-1.png'
        alt='Floral Preferences'
        width='100%'
        height='auto'
    />
</Col>
      <Col xs={24} /* md={10} */>
        <Form.Item
          name='colorPalette'
          label='Color Palette'
          rules={[{ required: true }]}
        >
          <Select
            size='large'
            placeholder='Select a Color Palette'
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Input
                    size='large'
                    placeholder='Custom'
                    ref={inputRef}
                    value={name}
                    onChange={onNameChange}
                    onKeyDown={handleKeyDown}
                  />
                  <Button
                    ref={addBtnRef}
                    size='large'
                    type='text'
                    icon={<PlusOutlined />}
                    onClick={addItem}
                  >
                    Add a Custom Color
                  </Button>
                </Space>
              </>
            )}
            options={items.map((item) => ({ label: item, value: item }))}
          />
        </Form.Item>
      </Col>
      {/* <Col xs={24} md={14}>
        <Form.Item
          name='specificFlowerPreferences'
          label='Specific Flower Preferences'
        >
          <TextArea rows={10} />
        </Form.Item>
      </Col> */}
      <Col xs={24}>
        {/* <Typography.Paragraph>
          Describe your wedding color palette. Don't be afraid to think outside
          the traditional palettes! Interested in going bold but unsure where to
          start? Our design team can help create something stunning!
        </Typography.Paragraph>
        <Typography.Paragraph italic>
          List color palette here, feel free to be as descriptive as possible
        </Typography.Paragraph> */}

        <Form.Item
          name='specificFlowerPreferences'
          label='Specific Flower Preferences'
        >
          <TextArea rows={10} placeholder='Please tell us more about the style and/or theme of your wedding!  You may list out any specific ideas, colors, flowers (love or hate!), or pieces you like.' />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <ActionBtnsContainer>
          <ActionBtn onClick={onPrevClick} text='Previous' />
          <ActionBtn type='primary' text='Continue (step 2 of 4)' htmlType='submit' />
        </ActionBtnsContainer>
      </Col>
    </Row>
  );
}
