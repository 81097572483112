import React, { useState, useRef, useCallback } from "react";
import axios from "axios";
import { Form, Steps, Progress, Alert, Modal, Button } from "antd";
import {
  FilePdfOutlined,
  PhoneOutlined,
  MailOutlined,
} from "@ant-design/icons";
import emailjs from "emailjs-com";
import ReactDOMServer from "react-dom/server";
import ResponseModal from "./Modal/SuccessModal";
import { jsPDF } from "jspdf";
import { ModalActionBtnContainer } from "./Popup/UI";
import { EventDetails } from "./Popup/EventDetails";
import { FloralPreferences } from "./Popup/FloralPreferences";
import { EventSpecifications } from "./Popup/EventSpecifications";
import VendorDetails from "./Popup/VendorDetails";

function Popup({ onClose }) {
  const [form] = Form.useForm();

  const formRef = useRef(null);
  const sectionRef = useRef(null);

  const [currentStep, setCurrentStep] = useState(0);
  const [maxStepReached, setMaxStepReached] = useState(0);
  const [quote, setQuote] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentSection, setCurrentSection] = useState(0);
  const [styledQuote, setStyledQuote] = useState(null);
  const [error, setError] = useState({ error: false, messgae: null });
  const [responseModal, setResponseModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);
  const [mailSend, setmailSend] = useState(false);
  const [, setHTMLString] = useState(null);
  // State for the ceremony and reception locations
  const [ceremonyLocation, setCeremonyLocation] = useState("");
  const [receptionLocation, setReceptionLocation] = useState("");
  const [generatePdfLoading, setGeneratePdfLoading] = useState(false);
  const [sendMailLoading, setSendMailLoading] = useState(false);

  const handleCeremonyLocationChange = useCallback((address) => {
    setCeremonyLocation(address);

    // Update allValues with the new ceremonyLocation
    setAllValues((prevValues) => ({
      ...prevValues,
      ceremonyLocation: address,
    }));
  }, []);

  const handleReceptionLocationChange = useCallback((address) => {
    setReceptionLocation(address);

    // Update allValues with the new receptionLocation
    setAllValues((prevValues) => ({
      ...prevValues,
      receptionLocation: address,
    }));
  }, []);

  // Helper function to disable minutes not divisible by 15
  const getDisabledMinutes = useCallback(() => {
    return Array.from({ length: 60 }, (_, minute) => minute).filter(
      (minute) => minute % 15 !== 0
    );
  }, []);

  // Helper function to disable hours outside of 7am to 7pm
  const getDisabledHours = useCallback(() => {
    return Array.from({ length: 24 }, (_, hour) => hour).filter(
      (hour) => hour < 7 || hour > 19
    );
  }, []);

  const scrollToTop = useCallback(() => {
    setTimeout(() => {
      if (formRef.current) {
        formRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 50);
  }, []);

  const nextStep = useCallback(() => {
    setMaxStepReached((ps) => ps + 1);
    setCurrentStep((currentStep) => currentStep + 1);
    scrollToTop();
  }, [scrollToTop]);
  const prevStep = useCallback(() => {
    setCurrentStep((currentStep) => currentStep - 1);
    scrollToTop();
  }, [scrollToTop]);

  const nextSection = useCallback(() => {
    // setProgress((progress) => Math.min(progress + 20, 100));
    setCurrentSection((currentSection) => currentSection + 1);
    // sectionRef.current.scrollLeft += sectionRef.current.offsetWidth;

    scrollToTop();
  }, [scrollToTop]);

  const prevSection = useCallback(() => {
    // setProgress((progress) => Math.max(progress - 20, 0));
    setCurrentSection((currentSection) => currentSection - 1);
    // sectionRef.current.scrollLeft -= sectionRef.current.offsetWidth;

    scrollToTop();
  }, [scrollToTop]);

  const [allValues, setAllValues] = useState({});

  const onFinish = async (values) => {
    console.log(JSON.stringify(values, null, 2));

    setAllValues((ps) => ({ ...ps, ...values }));

    if (currentStep === 3) {
      setLoading(true);
      await getFloralQuote({ ...allValues, ...values });
      setLoading(false);
    } else {
      nextStep();
    }
  };

  const generatePrompt = (values) => {
    let weddingDateString =
      values.weddingDate && values.weddingDate.format
        ? values.weddingDate.format("YYYY-MM-DD")
        : "Date not provided";
    let ceremonyTime =
      values.ceremonyTime && values.ceremonyTime.format
        ? values.weddingDate.format("HH:MM")
        : "Time not provided";
    let receptionTime =
      values.receptionTime && values.receptionTime.format
        ? values.receptionTime.format("HH:MM")
        : "Time not provided";
    let prompt = `Craft a detailed and itemized floral estimate in HTML table format for a wedding tailored to ${values.fullName || "the customer"
      }. The wedding is scheduled for ${weddingDateString}. The floral arrangements should match the following wedding specifications and include short descriptions of each arrangement, emphasizing the labor, quality, and special Floral preferences of the customer, price point, color palette, and any other relevant details:\n`;

    if (values.ceremonyLocation || values.bridesmaidsBouquets) {
      prompt += `- Ceremony Venue: `;
      if (values.ceremonyLocation) {
        prompt += `Location ${values.ceremonyLocation}`;
      }
      if (values.ceremonyTime) {
        prompt += `, Time ${ceremonyTime}`;
      }
      prompt += `\n`;
    }

    if (values.receptionLocation || values.receptionTime) {
      prompt += `- Reception Venue: `;
      if (values.receptionLocation) {
        prompt += `Location ${values.receptionLocation}`;
      }
      if (values.receptionTime) {
        prompt += `, Time ${receptionTime}`;
      }
      prompt += `\n`;
    }

    if (values.colorPalette) {
      prompt += `- Color Palette: ${values.colorPalette}\n`;
    }

    if (values.specificFlowerPreferences) {
      prompt += `- Specific Flower Preferences: ${values.specificFlowerPreferences}\n`;
    }

    if (values.numberOfGuests) {
      prompt += `- Guest Count: ${values.numberOfGuests}\n`;
    }

    if (values.budget) {
      prompt += `- Wedding Budget: ${values.budget}\n`;
    }

    // MOH bouquet
    if (values.mohBouquet && values.noOfMohBouquet) {
      prompt += `- MOH Bouquets: ${values.noOfMohBouquet} (Preferences: ${values.mohBouquetPreferences || "Not provided"})\n`;
    }

    // Bridesmaids bouquet
    if (values.bridesmaidsBouquet && values.noOfBridesmaidsBouquet) {
      prompt += `- Bridesmaids Bouquets: ${values.noOfBridesmaidsBouquet} (Preferences: ${values.bridesmaidsBouquetPreferences || "Not provided"})\n`;
    }

    // Jr. Bridesmaids bouquet
    if (values.jrBridesmaidsBouquet && values.noOfJrBridesmaidsBouquet) {
      prompt += `- Jr. Bridesmaids Bouquets: ${values.noOfJrBridesmaidsBouquet} (Preferences: ${values.jrBridesmaidsBouquetPreferences || "Not provided"})\n`;
    }

    // Flower Girl(s) details
    if (values.flowerGirl === "yes") {
      prompt += `- Flower Girl(s) Age(s): ${values.flowerGirlAge || "Not provided"}\n`;
      prompt += `- Flower Girl Floral Preferences: ${values.flowerGirlFloralsPreferences || "Not provided"}\n`;
    }

    // Wrist Corsage(s) For Mother(s)
    if (values.wristCorsageForMother === "yes") {
      prompt += `- Wrist Corsage(s) for Mother(s): ${values.numberOfWristCorsageForMother || "Not provided"}\n`;
    }

    // Wrist Corsage(s) For Grandmother(s)
    if (values.wristCorsageForGrandmother === "yes") {
      prompt += `- Wrist Corsage(s) for Grandmother(s): ${values.numberOfWristCorsageForGrandmother || "Not provided"}\n`;
    }

    // Groom Boutonniere
    if (values.groomBoutonniere) {
      prompt += `- Groom Boutonniere Preferences: ${values.groomBoutonnierePreferences || "Not provided"}\n`;
    }

    // Groomsmen Boutonnieres
    if (values.groomsmenBoutonniere) {
      prompt += `- Groomsmen Boutonnieres: ${values.noOfGroomsmenBoutonniere || "Not provided"}\n`;
    }

    // Fathers Boutonnieres
    if (values.fathersBoutonniere) {
      prompt += `- Fathers Boutonnieres: ${values.noOfFatherBoutonniere || "Not provided"}\n`;
    }

    // Grandfathers Boutonnieres
    if (values.grandfathersBoutonniere) {
      prompt += `- Grandfathers Boutonnieres: ${values.noOfGrandfatherBoutonniere || "Not provided"}\n`;
    }

    // Ring Bearer
    if (values.ringBearer) {
      if (values.ringBearerType === "male") {
        prompt += `- Ring Bearer: Male (Boutonniere is needed)\n`;
      } else if (values.ringBearerType === "female") {
        prompt += `- Ring Bearer: Female (Wrist corsage is needed)\n`;
      } else if (values.ringBearerType === "none") {
        prompt += `- Ring Bearer: None\n`;
      }
    }

    // Florals for the Aisle
    if (values.floralsAisle) {
      prompt += `- Florals for the Aisle: ${values.floralsAisleDescription || "Not provided"}\n`;
    }

    // Pew Markers
    if (values.needPewMarkers) {
      prompt += `- Pew Markers Count: ${values.pewMarkersCount || "Not provided"}\n`;
    }

    // Floral Arch
    if (values.needArch) {
      prompt += `- Floral Arch Description: ${values.archDescription || "Not provided"}\n`;
    }

    // Reception Table Flowers
    if (values.receptionTableFlowers) {
      prompt += "Reception Table Flowers:\n";
      values.tableFlowers.forEach(table => {
        prompt += `  - Type: ${table.type}, Count: ${table.count || (table.type === "sweetheart" ? 1 : "Not provided")}\n`;
      });
    }

    // Additional table floral needs
    if (values.additionalTablesDescription) {
      prompt += `- Additional table floral needs: ${values.additionalTablesDescription}\n`;
    }

    // Florals for the Mantle
    if (values.floralsMantle) {
      prompt += `- Florals for the Mantle: Yes\n`;
    }

    // Florals for the Staircase
    if (values.floralsStaircase) {
      prompt += `- Florals for the Staircase: ${values.staircaseDescription || "Not provided"}\n`;
    }

    // Florals for the Cake/Dessert Table or Loose Flowers for the Cake
    if (values.floralsCakeTable) {
      prompt += `- Florals for the Cake/Dessert Table: ${values.cakeTableDescription || "Not provided"}\n`;
    }


    prompt +=
      "Provide an itemized breakdown of costs in a table format for each floral category, keeping in mind the customer's budget. If certain flower choices don't align with the wedding's scale, suggest appropriate budget adjustments. If total is more than what customer mentioned as the wedding budget, then have a note that its been adjusted based off the size of the wedding.\n";
    prompt += `The budget range is ${values.budget}. The estimate should maximize the budget, ensuring unparalleled value and top-tier quality. In the table, columns should be 'Item', 'Qty', 'Description', and 'Cost'. Each description should delve into the detail, labor, and premium nature of the flowers, justifying the associated cost.\n`;
    prompt +=
      "The table should have columns for 'Item', 'Qty', 'Description', and 'Price'. Conclude with a statement that emphasizes the value and personal touch offered, without mentioning the budget utilization strategy. Sign off as Anna D., Weddings & Events Director, from Flower Boom Dallas LLC.";

    return prompt;
  };

  const getFloralQuote = async (values) => {
    startProgress();
    try {
      console.log("Sending API request..."); // Log before sending the request
      const promptMessage = generatePrompt(values);
      console.log("propmept vaues>>>>>>", promptMessage);
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content:
                "You are a knowledgeable florist providing detailed and itemized floral estimates for weddings.",
            },
            {
              role: "user",
              content: promptMessage,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`, // Access the API key from the .env file
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Received API response:", response.data); // Log after receiving the response
      if (
        Array.isArray(response?.data?.choices) &&
        response.data.choices.length > 0
      ) {
        const [choice] = response?.data?.choices;
        const content = choice?.message?.content?.trim() ?? null;
        setQuote(content);
        // renderStyledQuote(content); // As the renderHtml(content) call will override the result
        renderhtml(content);
        console.log("Quote state updated", content); // Log after updating the state
      }
    } catch (error) {
      console.error("Error fetching quote:", error);
      setError((prevState) => ({
        ...prevState,
        error: true,
        messgae: "some thing went wrong try again or contact with admin",
      }));
      setTimeout(() => {
        setError((prevState) => ({
          ...prevState,
          error: false,
          messgae: null,
        }));
      }, 9000);
    } finally {
      setLoading(false);

      console.log("Loading state set to false"); // Log loading state
    }
  };

  // const renderStyledQuote = (quote) => {
  //   const styledContent = (
  //     <div>
  //       <div
  //         // style={{
  //         //   fontFamily: "italic",
  //         //   fontSize: "1em",
  //         //   lineHeight: "1.5",
  //         //   padding: "20px",
  //         //   borderRadius: "10px",
  //         //   overflowY: "auto",
  //         //   boxSizing: "border-box",
  //         //   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  //         // }}
  //         dangerouslySetInnerHTML={{ __html: quote }}
  //       />
  //     </div>
  //   );
  //   setStyledQuote(styledContent);
  //   return styledContent;
  // };

  const renderhtml = (quote) => {
    const styledContent = (
      <div
        id='content'
        style={{
          padding: "20px",
          // overflow: "auto",
          boxSizing: "border-box",
        }}
        dangerouslySetInnerHTML={{ __html: quote }}
      />
    );
    setStyledQuote(styledContent);
    return styledContent;
  };
  const sendEmail = () => {
    console.log("Sending email with styledQuote:", styledQuote); // Log before sending email

    // Convert the styledQuote React component to an HTML string
    const quoteHTML = ReactDOMServer.renderToString(styledQuote);
    setHTMLString(quoteHTML);
    console.log("content send to user", quoteHTML);

    // Define the parameters for the email
    const emailParams = {
      to_email: "flowerboomdallas@gmail.com",
      subject: "New Floral Quote Request",
      customer_name: allValues?.fullName,
      customer_phone: allValues?.phone,
      customer_email: allValues?.email,
      wedding_date: allValues?.weddingDate,
      wedding_vanue: allValues?.receptionLocation,
      ceremony_vanue: allValues?.ceremonyLocation,
      rawHTML: quoteHTML, // This will replace {{rawHTML}} in your EmailJS template
    };

    setSendMailLoading(true);
    // Send the email using EmailJS
    emailjs
      .send(
        "service_imul84s",
        "template_ebv3q6f",
        emailParams,
        "myxzgv1xQl7eTgc8A"
      )
      .then((response) => {
        console.log("Email successfully sent!", response);
        setResponseModal(true);
        setmailSend(true);
        setHasError(false);
        setSendMailLoading(false);
      })
      .catch((error) => {
        console.error("Email sending failed:", error);
        setResponseModal(true);
        setHasError(true);
        setSendMailLoading(false);
      });
  };

  const startProgress = () => {
    const interval = setInterval(() => {
      setProgressPercent((prevPercent) => {
        if (prevPercent < 100) {
          // return prevPercent + 2.5;
          return prevPercent + 0.56;
        }
        clearInterval(interval);
        return prevPercent;
      });
    }, 1000);
  };

  const generatePDF = () => {
    const inputElement = document.getElementById("content");
    const pdf = new jsPDF("p", "mm", "a4"); // A4 size

    const pdfWidth = 210; // A4 width in mm
    const contentWidth = inputElement.scrollWidth;
    const scale = pdfWidth / contentWidth; // calculate the scale

    setGeneratePdfLoading(true);
    pdf
      .html(inputElement, {
        html2canvas: { scale: scale }, // set the scale
        callback: function (doc) {
          doc.save("FlowerBoomDallas.pdf");
        },
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setGeneratePdfLoading(false);
      });
  };

  return (
    // <ModalContainer>
    // <PopupModal modalSize={modalSize}>
    //   <ModalHeader>
    //     {modalSize.maximize ? (
    //       <ModalFullScreenExitIcon onClick={() => handleSize(false)} />
    //     ) : (
    //       <ModalFullScreenIcon onClick={() => handleSize(true)} />
    //     )}
    //     <ModalCloseIcon onClick={onClose} />
    //   </ModalHeader>
    <>
      <Modal
        title={
          <div>
            <h2>Floral Estimate Request Form</h2>
            <h4>Submit your details and get an instant detailed quote with the help of AI</h4>
          </div>
        }
        centered
        open={true}
        onOk={onClose}
        onCancel={onClose}
        width={1000}
        footer={null} // if footer is null then action buttons does not show
        okButtonProps={{
          size: "large",
          loading: loading,
          disabled: currentStep < 3,
        }}
        okText='Submit'
        cancelButtonProps={{
          size: "large",
          disabled: loading,
        }}
        confirmLoading
        cancelText='Close'
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100%",
              padding: "40px",
              paddingTop: '0px',
            }}
          >
            <>
              <div style={{ width: "100%" }}>
                <Progress
                  percent={progressPercent.toFixed(2)}
                  status='active'
                  strokeColor={{ "0%": "#6c23a0", "100%": "#6c23a0" }}
                />
              </div>
              <video
                src='https://cdn.shopify.com/videos/c/o/v/9312a699f19c42b0a23cccb11e36283d.mp4'
                controls={false}
                autoPlay
                onClick={(e) => {
                  if (e.target.paused) {
                    e.target.play();
                  } else {
                    e.target.pause();
                  }
                }}
                style={{ width: "100%", height: "450px", cursor: "pointer" }}
              />
            </>
          </div>
        ) : quote ? (
          <>
            {styledQuote ? styledQuote : "Could not Find HTML To Render"}

            <ModalActionBtnContainer>
              {mailSend && (
                <Button
                  type='primary'
                  size='large'
                  icon={<FilePdfOutlined color='white' />}
                  onClick={generatePDF}
                  loading={generatePdfLoading}
                  disabled={generatePdfLoading}
                >
                  Download PDF
                </Button>
              )}

              {!mailSend && (
                <Button
                  type='primary'
                  size='large'
                  icon={<MailOutlined color='white' />}
                  onClick={sendEmail}
                  loading={sendMailLoading}
                  disabled={sendMailLoading}
                >
                  Yes, I'm Interested!
                </Button>
              )}

              <Button
                type='primary'
                size='large'
                icon={<PhoneOutlined color='white' />}
                onClick={() =>
                  window.open(
                    "https://flowerboomdallas.hbportal.co/schedule/649e275d8d3d672f51f03f1e?wid=64aeea376678c00b872e0680",
                    "_blank"
                  )
                }
              >
                Free Consultation
              </Button>
            </ModalActionBtnContainer>
          </>
        ) : (
          <>
            {/* <Typography.Title level={2} style={{ marginTop: 0 }}>
              Floral Estimate Request Form
            </Typography.Title> */}
            <Form onFinish={onFinish} form={form} layout='vertical'>
              <div ref={formRef}>
                <Steps
                  direction='vertical'
                  current={currentStep}
                  onChange={setCurrentStep}
                  responsive
                  items={[
                    {
                      disabled: maxStepReached < 0,
                      title: "Event Details",
                      description: currentStep === 0 && (
                        <EventDetails
                          ceremonyLocation={ceremonyLocation}
                          handleCeremonyLocationChange={
                            handleCeremonyLocationChange
                          }
                          getDisabledHours={getDisabledHours}
                          getDisabledMinutes={getDisabledMinutes}
                          receptionLocation={receptionLocation}
                          handleReceptionLocationChange={
                            handleReceptionLocationChange
                          }
                        />
                      ),
                    },
                    {
                      disabled: maxStepReached < 1,
                      title: "Floral Preferences",
                      description: currentStep === 1 && (
                        <FloralPreferences onPrevClick={prevStep} />
                      ),
                    },
                    {
                      disabled: maxStepReached < 2,
                      title: "Event Specifications",
                      description: currentStep === 2 && (
                        <EventSpecifications
                          form={form}
                          progress={progress}
                          sectionRef={sectionRef}
                          currentSection={currentSection}
                          prevSection={prevSection}
                          nextSection={nextSection}
                          nextStep={nextStep}
                          prevStep={prevStep}
                          setProgress={setProgress}
                        />
                      ),
                    },
                    {
                      disabled: maxStepReached < 3,
                      title: "Last Few Questions",
                      description: currentStep === 3 && (
                        <VendorDetails form={form} onPrevClick={prevStep} />
                      ),
                    },
                  ]}
                />
              </div>

              {error?.error && (
                <Alert message={error?.messgae} type='error' showIcon />
              )}
            </Form>
          </>
        )}
        {/* </PopupModal> */}
      </Modal>
      <ResponseModal
        responseModal={responseModal}
        hasError={hasError}
        setResponseModal={setResponseModal}
      />
    </>
    // </ModalContainer>
  );
}

export default Popup;
