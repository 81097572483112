import React from 'react';
import Helmet from 'react-helmet';

const StructuredData = () => {
    const schema = {
        "@context": "https://schema.org",
        "@type": "Florist",
        "name": "Flower Boom Dallas - Weddings & Events",
        "description": "Expert wedding floral design services in Dallas, offering instant quotes to help you plan your dream wedding with ease.",
        "url": "https://weddings.flowerboomdallas.com",
        "telephone": "+14694689333",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "8687 N Central Expy Ste 1145",
            "addressLocality": "Dallas",
            "addressRegion": "TX",
            "postalCode": "75225",
            "addressCountry": "US"
        },
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": 32.8687357,
            "longitude": -96.7718582
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "reviewCount": "47"
        },
        "openingHoursSpecification": [
            {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Monday",
                "opens": "09:00",
                "closes": "20:00"
            },
            {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Tuesday",
                "opens": "09:00",
                "closes": "20:00"
            },
            {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Wednesday",
                "opens": "09:00",
                "closes": "20:00"
            },
            {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Thursday",
                "opens": "09:00",
                "closes": "20:00"
            },
            {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Friday",
                "opens": "09:00",
                "closes": "20:00"
            },
            {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Saturday",
                "opens": "09:00",
                "closes": "20:00"
            },
            {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Sunday",
                "opens": "09:00",
                "closes": "17:00"
            }
        ],
        "offers": {
            "@type": "Offer",
            "itemOffered": {
                "@type": "Service",
                "name": "Wedding Floral Design",
                "hasOfferCatalog": {
                    "@type": "OfferCatalog",
                    "name": "Wedding Floral Packages",
                    "itemListElement": [
                        {
                            "@type": "Offer",
                            "itemOffered": {
                                "@type": "Product",
                                "name": "Intimate Affair Package",
                                "description": "Ideal for weddings with up to 50 guests. Includes Bridal Bouquet, 2 Bridesmaid Bouquets, 4 Boutonnieres, 1 Floral Centerpiece for the Head Table, 5 Table Centerpieces, and Delivery & Setup.",
                                "offers": {
                                    "@type": "Offer",
                                    "price": "1499",
                                    "priceCurrency": "USD"
                                }
                            }
                        },
                        {
                            "@type": "Offer",
                            "itemOffered": {
                                "@type": "Product",
                                "name": "Classic Elegance Package",
                                "description": "For weddings with 50-150 guests. Includes Bridal Bouquet, 4 Bridesmaid Bouquets, 6 Boutonnieres, 2 Corsages, 1 Floral Arch or Backdrop, 1 Floral Centerpiece for the Head Table, 10 Table Centerpieces, Petals for Aisle, Cake Flowers, and Delivery & Setup.",
                                "offers": {
                                    "@type": "Offer",
                                    "price": "4999",
                                    "priceCurrency": "USD"
                                }
                            }
                        },
                        {
                            "@type": "Offer",
                            "itemOffered": {
                                "@type": "Product",
                                "name": "Grand Celebration Package",
                                "description": "Suited for weddings with more than 150 guests. Includes Bridal Bouquet, 6 Bridesmaid Bouquets, 8 Boutonnieres, 4 Corsages, 1 Floral Arch or Backdrop, 1 Floral Centerpiece for the Head Table, 20 Table Centerpieces, Petals for Aisle, Cake Flowers, Floral Chandelier or Hanging Installation, and Delivery & Setup.",
                                "offers": {
                                    "@type": "Offer",
                                    "price": "9999",
                                    "priceCurrency": "USD"
                                }
                            }
                        }
                    ]
                }
            }
        },          
        "additionalProperty": [
            {
                "@type": "PropertyValue",
                "name": "In-store shopping",
                "value": "Available"
            },
            {
                "@type": "PropertyValue",
                "name": "Curbside pickup",
                "value": "Available"
            },
            {
                "@type": "PropertyValue",
                "name": "Delivery",
                "value": "Available"
            }
        ],
        "identifiesAs": ["veteran-owned", "Latino-owned"]
    };

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(schema)}
            </script>
        </Helmet>
    );
};

export default StructuredData;
