import {Button, Col, Flex, Row, Typography} from "antd"
import React, {useMemo, useState} from "react"
import {IoCheckmarkOutline} from "react-icons/io5"
import {GoBell} from "react-icons/go"
// Components
import { RequestPricingFormModal } from "components/RequestPricingFormModal/RequestPricingFormModal"

import "styles/choose-path-section.scss"

export function ChoosePathSection({ history }) {
  const detailedQuoteOptions = useMemo(
    () => [
      "Detailed questionnaire to capture your vision",
      "Extensive selection of wedding flowers and arrangements",
      "AI-generated quote with precise pricing and item details",
      "Approximately 15 minutes to complete",
      "Perfect for when you want everything just quick & right",
    ],
    []
  )
  const fastAndConvenientOptions = useMemo(
    () => [
      "Short form with a prewritten message",
      "Provide only essential event details",
      "Personalized follow-up from our wedding florist",
      "Takes just 5 seconds to submit",
      "Ideal for initial budgeting and quick inquiries",
    ],
    []
  )

  return (
    <div className="choose-path-container">
      <div className="choose-path-top-section-container">
        <div>
          <Typography.Title
            level={1}
            style={{color: "#fff", textAlign: "center", marginBottom: "24px"}}
          >
            Choose Your Path to Perfect Wedding Florals
          </Typography.Title>

          <Typography.Paragraph style={{color: "#fff", textAlign: "center"}}>
            Our 'Detailed AI Quote' provides an extensive and personalized
            planning tool, while the 'Quick Florist Quote' offers a speedy way
            to obtain an initial estimate. Both are steps towards making your
            special day unforgettable.
          </Typography.Paragraph>
        </div>
      </div>
      <div className="choose-path-options-row-container">
        <Row gutter={[32, 32]}>
          <Col className="choose-path-option-col" lg={12}>
            <div className="choose-path-option">
              <Flex className="choose-path-option-header">
                <div>
                  <Typography.Text>
                    Ideal for thorough planning and precision
                  </Typography.Text>
                  <Typography.Title
                    // level={1}
                    className="choose-path-option-title"
                  >
                    Detailed Quote
                  </Typography.Title>
                  <Typography.Text>
                    Approximately 15 minutes to complete
                  </Typography.Text>
                </div>

                <img src="/images/idea-bulb.png" alt="Idea" height="120px" />
              </Flex>

              <Flex gap={8} vertical>
                {detailedQuoteOptions.map((option, index) => (
                  <Flex key={index} gap={8}>
                    <IoCheckmarkOutline color="#0E642AFF" fontSize="22px" />
                    <Typography.Text>{option}</Typography.Text>
                  </Flex>
                ))}
              </Flex>

              <Button
                className="choose-path-option-btn"
                onClick={() => history.push('/instant-wedding-quote')}
              >
                Begin Detailed Quote
              </Button>
            </div>
          </Col>

          <Col className="choose-path-option-col" lg={12}>
            <div className="choose-path-option">
              <Flex className="choose-path-option-header choose-path-option-2-header">
                <div>
                  <Typography.Text>
                    Best for a quick overview and rapid response.
                  </Typography.Text>
                  <Typography.Title
                    level={1}
                    className="choose-path-option-title"
                  >
                    Fast & Convenient
                  </Typography.Title>
                  <Typography.Text>
                    Takes just 5 seconds to submit
                  </Typography.Text>
                </div>

                <img src="/images/magic-wand.png" alt="Idea" height="120px" />
              </Flex>

              <Flex gap={8} vertical>
                {fastAndConvenientOptions.map((option, index) => (
                  <Flex key={index} gap={8} align="center">
                    <IoCheckmarkOutline color="#5A3970FF" fontSize="22px" />
                    <Typography.Text>{option}</Typography.Text>
                  </Flex>
                ))}
              </Flex>

              {/* <Button className="choose-path-option-btn choose-path-option-btn-2">
                Request Quick Quote
              </Button> */}
              <RenderQuickQuoteButton />
            </div>
          </Col>
        </Row>

        <Typography.Title
          level={1}
          className="not-sure-text"
          style={{
            marginBottom: "16px",
            fontWeight: "700",
            lineHeight: "48px",
            color: "#171A1FFF",
            marginTop: "24px",
          }}
        >
          Not sure which to choose?
        </Typography.Title>

        <Flex
          align="center"
          gap={12}
          style={{
            background: "#DEE1E63D",
            borderRadius: "8px",
            padding: "22px 28px",
          }}
        >
          <GoBell size="36px" className="bell-icon" />
          <Typography.Text style={{color: "#171A1FFF", fontSize: "14px"}}>
            Unsure of which path to take? Our 'Detailed AI Quote' provides an
            extensive and personalized planning tool, while the 'Quick Quote'
            offers a speedy way to obtain an initial estimate. Both are steps
            towards making your special day unforgettable.
          </Typography.Text>
        </Flex>
      </div>
    </div>
  )
}


function RenderQuickQuoteButton() {
  const requestFormText = useMemo(() => {
    return {
      title: "Message vendor",
      description:
        "Fill out the form below to request information from Flower Boom Dallas LLC.",
      initialMessage:
        "We were excited to find you on Flower Boom Dallas! We'd love to get more information about your services and any packages you have available.",
      submitBtnText: "Send",
    }
  }, [])

  const [openModalAndSetTextObject, setOpenModalAndSetTextObject] =
    useState(null)

    
  return (
    <>
      <RequestPricingFormModal
        open={!!openModalAndSetTextObject}
        setOpen={setOpenModalAndSetTextObject}
        textObject={openModalAndSetTextObject}
      />
      <Button 
        className='choose-path-option-btn choose-path-option-btn-2'
        onClick={() => setOpenModalAndSetTextObject(requestFormText)}
      >
        Request Quick Quote
      </Button>
    </>
  );
}
