import { Button, Col, Form, Menu, Row } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import {
  CloseOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  EffectFade,
  Autoplay,
} from "swiper";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";

import "styles/store-front-gallery.scss";
import { IoImageOutline } from "react-icons/io5";
import { MdSmartDisplay } from "react-icons/md";
import { RequestPricingForm } from "components/RequestPricingForm/RequestPricingForm";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

export default function StoreFrontGallery(props) {
  const { history, match } = props;
  const { mediaType } = match.params;

  const imageUrls = useMemo(() => {
    return [
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_0186.jpg?v=1710216876",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_0191.jpg?v=1710216878",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_0193.jpg?v=1710216877",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_0190.jpg?v=1710216858",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_0188.jpg?v=1710216864",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/350823833_106814195757949_2395589210023774584_n.jpg?v=1710216771",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/350938478_659563769343708_4270358682351357312_n.jpg?v=1710216769",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/350772610_1000850054626827_6594566934767196212_n.jpg?v=1710216767",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/350646734_172433512418046_6291202479141396187_n.jpg?v=1710216771",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/350832977_732267202033189_3856702838853080511_n.jpg?v=1710216771",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/Brianne_Ivan-Wedding-627.jpg?v=1710216799",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/Brianne_Ivan-Wedding-619.jpg?v=1710216794",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/Brianne_Ivan-Wedding-541.jpg?v=1710216800",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/Brianne_Ivan-Wedding-534.jpg?v=1710216814",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/Brianne_Ivan-Wedding-365.jpg?v=1710216799",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/Brianne_Ivan-Wedding-59.jpg?v=1710216804",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/Brianne_Ivan-Wedding-16.jpg?v=1710216814",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_4566.jpg?v=1710216861",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_4576.jpg?v=1710216834",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_4581.jpg?v=1710216793",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_4582.jpg?v=1710216794",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_4573.jpg?v=1710216859",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_3173.jpg?v=1710216834",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_3170.jpg?v=1710216836",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_3169.jpg?v=1710216845",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_3168.jpg?v=1710216835",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_3161.jpg?v=1710216835",
      "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/img-6307_51_2055487-168903822678687.webp?v:2023-09-06T05:42:29.542Z",
      "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/purple-jasmine-centerpiece_51_2055487-169163449611963.webp?v=2023-09-06T05:42:29.529Z",
      "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/img-5348_51_2055487-166881243955612.webp?v=2023-09-06T05:42:29.542Z",
      "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/img-5217_51_2055487-166881243966053.webp?v=2023-09-06T05:42:29.560Z",
      "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/tall-centerpiece-white-pink_51_2055487-169163449046087.webp?v=2023-09-06T05:42:29.723Z",
      "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/brianneivan-wedding-633_51_2055487-169155192853041.webp?v=2023-09-06T05:42:29.553Z",
      "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/green-white-aisle_51_2055487-169163447888235.webp?v=2023-09-06T05:42:29.540Z",
      "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/img-5341_51_2055487-166881513117265.webp?v=2023-09-06T05:42:29.541Z",
      "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/balls-centerpiece-tall_51_2055487-169163447096918.webp?v=2023-09-06T05:42:29.533Z",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_6334.jpg?v=1710216781",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_6318.jpg?v=1710216776",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_6325.jpg?v=1710216775",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_6319.jpg?v=1710216773",
      "https://cdn.shopify.com/s/files/1/0466/6893/9420/files/IMG_6328.jpg?v=1710216771"
    ];
  }, []);

  const items = useMemo(() => {
    return [
      {
        label: "Photos",
        key: "photos",
        icon: <IoImageOutline size='22px' />,
      },
      {
        label: "Videos",
        key: "videos",
        icon: <MdSmartDisplay size='22px' />,
      },
    ];
  }, []);

  const [slideToShow, setSlideToShow] = useState(null);

  const handleHideSlider = useCallback(() => {
    setSlideToShow(null);
  }, []);

  const sliderMarkup = (
    <div>
      <Swiper
        className='swiper-styles'
        pagination={true}
        navigation={true}
        loop={true}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        initialSlide={slideToShow}
      >
        {imageUrls.map((url, index) => (
          <SwiperSlide
            key={index}
            className='store-front-media-container'
            style={{
              width: "100%",
              // height: "calc(100vh - 170px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Button
              type='text'
              size='large'
              style={{ position: "absolute", top: "24px", right: "24px" }}
              shape='circle'
              onClick={handleHideSlider}
            >
              <CloseOutlined />
            </Button>
            <img
              src={url}
              alt={`Slide ${index}`}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "cover",
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );

  const imagesGridMarkup = (
    <div className='storefrontGalleryMosaicFullscreen__list__container'>
      <div className='storefrontGalleryMosaicFullscreen__list'>
        {imageUrls.map((url, index) => (
          <div
            key={index}
            className='storefrontGalleryMosaicFullscreen__item'
            onClick={() => setSlideToShow(index)}
          >
            <img src={url} alt={"gallery-img" + index} />
          </div>
        ))}
      </div>
    </div>
  );

  const videoMarkup = (
    <div className='store-front-media-container'>
      <iframe
        src='//www.youtube.com/embed/pywMvwYk5O8?enablejsapi=1'
        height='100%'
        title='wedding-video'
        width='560'
        style={{
          display: "block",
          height: "100%",
          width: "100%",
          background: "#222",
        }}
      ></iframe>
    </div>
  );

  return (
    <div>
      <Button
        style={{ margin: "10px 12px" }}
        size='large'
        onClick={() => history.push("/")}
      >
        <ArrowLeftOutlined /> Flower Boom Dallas LLC
      </Button>

      <Menu
        // onClick={(e) => setCurrent(e.key)}
        onClick={(e) => history.push("/store-front-gallery/" + e.key)}
        // selectedKeys={[current]}
        selectedKeys={[mediaType]}
        mode='horizontal'
        items={items}
        style={{ marginBottom: "28px" }}
      />

      <Row
        gutter={[16, 16]}
        style={{
          margin: "0px",
        }}
      >
        <Col xs={24} lg={16} xl={18}>
          {mediaType === "photos"
            ? slideToShow !== null
              ? sliderMarkup
              : imagesGridMarkup
            : videoMarkup}
        </Col>
        <Col xs={24} lg={8} xl={6}>
          <RenderRequestPricingForm />
          {/* <div>
            <div style={{ padding: "24px", border: "1px solid #f0f0f0" }}>
              <Flex align='center' justify='center' gap={8} vertical>
                <Typography.Text style={{ fontWeight: "600" }}>
                  MESSAGES
                </Typography.Text>
                <Typography.Text style={{ fontWeight: 500, display: "flex" }}>
                  You messaged Flower Boom Dallas LLC on 1/20/24.
                </Typography.Text>
                <Button type='link'>View conversation {">"}</Button>
              </Flex>
            </div>

            <Flex
              vertical
              gap={28}
              style={{
                border: "1px solid #f0f0f0",
                borderTop: "0px",
                padding: "24px 16px",
              }}
            >
              <Typography.Text style={{ textAlign: "center" }}>
                HOW DID IT GO?
              </Typography.Text>

              <Flex
                align='center'
                justify='space-between'
                style={{ padding: "0px 48px" }}
              >
                <Flex align='center' gap={6} vertical>
                  <Button
                    shape='circle'
                    style={{ height: "48px", width: "48px" }}
                    danger
                  >
                    <CloseOutlined size='36px' />
                  </Button>
                  <Typography.Text>Not a good fit</Typography.Text>
                </Flex>

                <Flex align='center' gap={6} vertical>
                  <Button
                    shape='circle'
                    style={{ height: "48px", width: "48px" }}
                  >
                    <CheckOutlined size='36px' />
                  </Button>
                  <Typography.Text>Great, hired!</Typography.Text>
                </Flex>
              </Flex>
            </Flex>
          </div> */}
        </Col>
      </Row>
    </div>
  );
}

function RenderRequestPricingForm() {
  const [form] = Form.useForm();

  const onResponseModalClose = useCallback(() => {
    form.resetFields();
  }, [form]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
        padding: "0px 24px",
        paddingTop: "24px",
        boxShadow: "0 2px 8px #00000026",
      }}
    >
      <RequestPricingForm
        title='Message Vendor'
        form={form}
        initialMessage='Hey there! We love your work and are interested in learning more about your services for our wedding.'
        onResponseModalClose={onResponseModalClose}
        submitBtnText='Send'
        fullWidthFields
      />
    </div>
  );
}
