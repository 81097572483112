import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import styled from "styled-components";

const StyledButton = styled(Button)`
  background: linear-gradient(135deg, #6c23a0, #333366);
  color: white;

  &:hover {
    border-color: white !important;
    color: white !important;
  }
`;

export function GradientBtn({ href, onClick, icon, text, className }) {
  return (
    <StyledButton
      icon={icon}
      onClick={onClick}
      size='large'
      color='white'
      className={className}
      href={href}
    >
      {text}
    </StyledButton>
  );
}

GradientBtn.prototype = {
  onClick: PropTypes.func,
  href: PropTypes.string,
  text: PropTypes.string.isRequired,
  icon: PropTypes.element,
  className: PropTypes.string,
};
