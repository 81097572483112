import { Button, Flex, Modal, Typography } from "antd";
import React from "react";
import { PiPhoneCallLight } from "react-icons/pi";

export function CallVendorModal({ open, onClose }) {
  return (
    <Modal
      title='Call Flower Boom Dallas LLC'
      centered
      open={open}
      onOk={onClose}
      onCancel={onClose}
      // width={768}
      footer={null} // if footer is null then action buttons does not show
      okButtonProps={{
        size: "large",
        // loading: loading,
        // disabled: currentStep < 3,
      }}
      okText='Send'
      cancelButtonProps={{
        size: "large",
        // disabled: loading,
      }}
      confirmLoading
      cancelText='Close'
    >
      <Flex gap={12} style={{ marginTop: "24px" }} vertical>
        <Typography.Paragraph style={{ fontSize: "14px" }}>
          Mention that you found them on Flower Boom Dallas LLC
        </Typography.Paragraph>

        <Button href='tel:4694689333' size='large'>
          <Flex align='center' justify='center' gap={6}>
            <PiPhoneCallLight size='22px' />
            469-468-9333
          </Flex>
        </Button>
      </Flex>
    </Modal>
  );
}
