import React from "react";
import Popup from "components/Popup";
import { FormContainer } from "components/Popup/UI";

function InstantWeddingQuote(props) {
  return (
    <FormContainer>
      <Popup onClose={() => props.history.goBack()} />
    </FormContainer>
  );
}

export default InstantWeddingQuote;
