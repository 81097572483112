import {
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Typography,
  Divider,
  Card
} from "antd";
import React from "react";
import { ActionBtnsContainer } from "./UI";
import { ActionBtn } from "./ActionBtn";

export function GroomGroomsmen({
  currentSection,
  form,
  prevSection,
  nextSection,
}) {
  const groomBoutonniere = Form.useWatch(["groomBoutonniere"], form);
  const groomsmenBoutonniere = Form.useWatch(["groomsmenBoutonniere"], form);
  const fathersBoutonniere = Form.useWatch(["fathersBoutonniere"], form);
  const grandfathersBoutonniere = Form.useWatch(["grandfathersBoutonniere"], form);
  const ringBearer = Form.useWatch(["ringBearer"], form);

  return (
    <div style={{ flex: "none", width: "100%", /* padding: "0px 4px" */ }}>
      <Typography.Title level={4} style={{ marginTop: 0, padding: "15px 0 15px 0" }}>
        Boutonnieres Details
      </Typography.Title>
      
      <Card bordered={false} style={{ marginBottom: "15px" }}>
        <Row>
          <Col xs={24}>
            <Form.Item name="groomBoutonniere" valuePropName="checked" initialValue={true}>
              <Checkbox>
                Boutonniere for the Groom
              </Checkbox>
            </Form.Item>
            <Form.Item name="groomBoutonnierePreferences" label="Describe specific style if any">
              <Input.TextArea rows={2} disabled={!groomBoutonniere} />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Divider />

      <Card bordered={false} style={{ marginBottom: "15px" }}>
        <Row>
          <Col xs={24}>
            <Form.Item name="groomsmenBoutonniere" valuePropName="checked" initialValue={false}>
              <Checkbox>
                Boutonnieres for Groomsmen
              </Checkbox>
            </Form.Item>
            <Form.Item label="Number of Groomsmen Boutonnieres" name="noOfGroomsmenBoutonniere" initialValue={1}>
              <InputNumber size="large" min={1} max={20} style={{ width: "100%" }} disabled={!groomsmenBoutonniere} />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Divider />

      <Card bordered={false} style={{ marginBottom: "15px" }}>
        <Row>
          <Col xs={24}>
            <Form.Item name="fathersBoutonniere" valuePropName="checked" initialValue={false}>
              <Checkbox>
                Boutonnieres for Father(s)
              </Checkbox>
            </Form.Item>
            <Form.Item label="Number of Fathers' Boutonnieres" name="noOfFatherBoutonniere" initialValue={1}>
              <InputNumber size="large" min={1} max={10} style={{ width: "100%" }} disabled={!fathersBoutonniere} />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Divider />

      <Card bordered={false} style={{ marginBottom: "15px" }}>
        <Row>
          <Col xs={24}>
            <Form.Item name="grandfathersBoutonniere" valuePropName="checked" initialValue={false}>
              <Checkbox>
                Boutonnieres for Grandfather(s)
              </Checkbox>
            </Form.Item>
            <Form.Item label="Number of Grandfathers' Boutonnieres" name="noOfGrandfatherBoutonniere" initialValue={1}>
              <InputNumber size="large" min={1} max={10} style={{ width: "100%" }} disabled={!grandfathersBoutonniere} />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Divider />

      <Card bordered={false} style={{ marginBottom: "15px" }}>
        <Row>
          <Col xs={24}>
            <Form.Item name="ringBearer" valuePropName="checked" initialValue={false}>
              <Checkbox>Ring Bearer</Checkbox>
            </Form.Item>
            <Form.Item name="ringBearerType">
              <Radio.Group disabled={!ringBearer}>
                <Radio value="male">Yes, Male, boutonniere is needed</Radio>
                <Radio value="female">Yes, Female, wrist corsage is needed</Radio>
                <Radio value="none">No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <ActionBtnsContainer>
        <ActionBtn onClick={prevSection} text="Previous" />
        <ActionBtn onClick={nextSection} type="primary" text="Next" />
      </ActionBtnsContainer>
    </div>
  );
}
