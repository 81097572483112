export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"64f7edaf098f0700199b65f4",
				"64f7edaf098f0700199b65f7"
			]
		},
		"64f7edaf098f0700199b65f4": {
			"id": "64f7edaf098f0700199b65f4",
			"name": "404",
			"pageUrl": "404"
		},
		"64f7edaf098f0700199b65f7": {
			"id": "64f7edaf098f0700199b65f7",
			"name": "index",
			"pageUrl": "index"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {
			"title": "Instant Wedding Floral Quotes | Flower Boom Dallas",
			"description": "Get an instant floral quote tailored for your wedding with Flower Boom Dallas. Save time, visualize your dream, and turn it into a floral reality.",
			"og:title": "Flower Boom Dallas: Your Dream Wedding, One Floral Design at a Time",
			"og:description": "Turn your wedding into a floral masterpiece with Flower Boom Dallas. Get an instant estimate and start your journey to a magical wedding today!",
			"og:image": "https://uploads.quarkly.io/64f7edaf098f0700199b65ef/images/tall-centerpiece-white-pink_51_2055487-169163449046087.webp?v=2023-09-06T05:42:29.723Z",
			"robotsFileType": "custom",
			"sitemapFileType": "custom",
			"customSitemapDomain": "weddings.flowerboomdallas.com",
			"customSitemapXml": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n<urlset xmlns=\"http://www.sitemaps.org/schemas/sitemap/0.9\">\n    <url>\n        <loc>https://weddings.flowerboomdallas.com/</loc>\n        <lastmod>2023-09-20</lastmod>\n        <changefreq>monthly</changefreq>\n        <priority>1.0</priority>\n    </url>\n</urlset>\n"
		}
	}
}