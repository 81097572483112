import React from 'react';
import { Modal } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const ResponseModal = ({ responseModal, hasError, setResponseModal, message }) => {
    return (
        <div>
            <Modal
                centered
                open={responseModal}
                footer={null}
                onCancel={() => setResponseModal(false)}
            >
                {hasError ?
                    <div style={{ padding: "30px 0px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", fontFamily: 'var(--qtheme-fontFamily-sans)' }}>
                        <CloseCircleOutlined style={{ fontSize: '40px', color: '#F21E1E' }} />
                        <span style={{
                            color: "#F21E1E", display: "block", fontWeight: "bold", fontSize: "18px",
                            letterSpacing: "1px", margin: "10px 0px 14px 0px"
                        }}>ERROR!</span>
                        <span style={{ color: "#4A4A4A", fontSize: "14px", lineHeight: "18px", marginBottom: "6px", display: "block", fontWeight: "bold", textAlign: "center" }}>
                            Thank you for your request. <br />
                            We are unable to continue the process.
                        </span>
                        Please try again to complete the request.
                        <button style={{ color: "#fff", background: "#F21E1E", width: "180px", border: "none", outline: "none", borderRadius: "4px", padding: "10px", marginTop: "18px" }} onClick={() => setResponseModal(false)}>Try Again</button>
                    </div>
                    :
                    <div style={{
                        padding: "30px 0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        fontFamily: 'var(--qtheme-fontFamily-sans)'
                    }}>
                        <CheckCircleOutlined style={{ fontSize: '40px', color: '#8E44AD' }} />
                        <span style={{
                            color: "#8E44AD",
                            display: "block",
                            fontWeight: "bold",
                            fontSize: "18px",
                            letterSpacing: "1px",
                            margin: "10px 0 14px"
                        }}>
                            SUCCESS
                        </span>
                        <span style={{
                            color: "#4A4A4A",
                            fontSize: "14px",
                            lineHeight: "18px",
                            marginBottom: "6px",
                            display: "block",
                            fontWeight: "bold",
                            textAlign: "center"
                        }}>
                            {message ?? 'Now you can Download a PDF copy of your free instant wedding floral quote.'}
                        </span>
                        <span style={{
                            color: "#4A4A4A",
                            fontSize: "14px",
                            lineHeight: "18px",
                            textAlign: "center",
                            marginTop: "10px"
                        }}>
                            Our Wedding Floral Specialist will reach out to you within the next 20-40 minutes.
                        </span>
                        <span style={{
                            color: "#4A4A4A",
                            fontSize: "14px",
                            lineHeight: "20px",
                            textAlign: "center",
                            marginTop: "10px"
                        }}>
                            Thank you for considering us for your special day!
                        </span>
                        <button style={{
                            color: "#fff",
                            background: "#8E44AD",
                            width: "180px",
                            border: "none",
                            outline: "none",
                            borderRadius: "4px",
                            padding: "10px",
                            marginTop: "18px"
                        }} onClick={() => setResponseModal(false)}>
                            Continue
                        </button>
                    </div>
                }
            </Modal>
        </div>
    )
}

export default ResponseModal;
