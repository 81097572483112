import { Checkbox, Form, Input, Typography } from "antd";
import React from "react";
import { ActionBtnsContainer } from "./UI";
import { ActionBtn } from "./ActionBtn";

const { TextArea } = Input;

export function BridalBouquetDetails({
  currentSection,
  form,
  prevSection,
  nextSection,
}) {
  // const bridesBouquet = Form.useWatch(["bridesBouquet"], form);

  return (
    <div style={{ flex: "none", width: "100%", /* padding: "0px 4px" */ }}>
      <Typography.Title level={4} style={{ marginTop: 0, padding: "15px 0 15px 0" }}>
        Bridal Bouquet Details
      </Typography.Title>

      <Form.Item
        name='bridesBouquet'
        valuePropName='checked'
        initialValue={true}
      >
        <Checkbox>Bride's Bouquet</Checkbox>
      </Form.Item>
      <img
        src='/images/bridal-bouquet-details-1.png'
        alt='Bridal Bouquet Details'
        width='100%'
        height='auto'
        hidden={currentSection !== 1}
      />
      <Typography.Paragraph style={{ fontStyle: 'italic' }}>
      Please give a description of the colors and style you like best. Any favorite flower types?  (If unsure, feel free to tell us your preferences!  (i.e. more white or more colorful) and we’ll create the magic!)
    </Typography.Paragraph>
     
      {/* <Typography.Title level={4} style={{ marginTop: 0 }}>
    Bridal Bouquet Details
  </Typography.Title>

  <Typography.Paragraph italic>
    Bridal Bouquets. Please give a description of the colors and style
    you like best. Any favorite flower types? (If unsure, feel free to
    tell us your preferences! (i.e. more white or more colorful) and
    we'll create the magic!).
  </Typography.Paragraph> */}

      
      <Form.Item
          name='bridalBouquetDescription'
          label='Bridal Bouquet Details'
        >
          <TextArea rows={10} placeholder='Start typing here...' />
        </Form.Item>

      {/* <SliderItem
      name='bridesmaidsBouquets'
      label="Bridesmaids' Bouquets"
      initialValue={1}
      min={1}
      max={20}
      marks={{
        1: "1",
        20: "20",
      }}
    /> */}
      <ActionBtnsContainer>
        {currentSection > 0 && (
          <ActionBtn onClick={prevSection} text='Previous' />
        )}
        <ActionBtn onClick={nextSection} type='primary' text='Next' />
      </ActionBtnsContainer>
    </div>
  );
}
